import React, { Component } from 'react';
import EventEmitter from '../../helper/Emitter';



class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: '',
      color: 'green',
      hidden: 'hidden'
    };
  }

  componentDidMount() {
  	EventEmitter.subscribe('showMessage', (event) => this.configureData(event, 'green'))
    EventEmitter.subscribe('showMessageError', (event) => this.configureData(event, 'red'))
  }

  configureData(data, color) {
    var time1 = 2000
    var time2 = 2400
    if(color === 'red') {
      time1 = 4000
      time2 = 4400
    }
  	this.setState({
  		show: true,
      color: color,
  		message: data
  	})
  	setTimeout(() => {
      this.setState({hidden: ''});
    }, 100);
  	setTimeout(() => {
      this.setState({hidden: 'hidden'});
    }, time1);
    setTimeout(() => {
      this.setState({show: false});
    }, time2);
  }

  render() {
  	if(this.state.show) {
  		return (
			<div className={'message '+this.state.color+' '+this.state.hidden}>
        <div className="bg"></div>
        <div className="message-text">{this.state.message}</div>
      </div>
		)
  	} else {
  		return (<div/>)
  	}
    
  }
}


export default Message;