import React, { Component } from 'react';
import ListClient from '../List/client';



class ModalAvatar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickElement(item) {
    this.hide()
    this.props.clickElement(item)
  }



  // Display stuff -----------------------------------------------------



  render() {
    var list = global.avatars
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">Select gallery image</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="clear sv-20"></div>
            <div className="scroll">
              {list.map(item => (
                <div className={'avatar selection lft '+item} onClick={() => this.clickElement(item)}></div>
              ))}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalAvatar;