import React, { Component } from 'react';



class InfoBar extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      value: props.value,
      color: props.color,
      width: props.width,
      small: props.small ?? false
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      value: props.value,
      color: props.color,
      width: props.width,
      small: props.small ?? false
    }
  }


  render() {
    return (
      <div className="info">
        <label>{this.state.label}</label>
        <div className="bar">
          <div className={'inner '+this.state.color} style={{width: this.state.width}}></div>
        </div>
        <p>{this.state.value}</p>
        <div className="clear"></div>
      </div>
    )
  }
}


export default InfoBar;