import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperBilling from '../../helper/Billing';
import Navigation from '../../components/Navigation';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberInvoicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      id: '',
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = lang.t('admin:label.invoice')+' - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var tmp = null
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    for(var item of global.invoices) {
      if(item.id === id) {
        tmp = item
      }
    }
    this.setState({
      item: tmp,
      id: id,
    })
  }


  getProductInfo(type) {
    var label = ''
    for(var item of global.products) {
      if(item.id === this.state.item.data.product) {
        label = item.data.name
        if(type === 'price') {
          label = HelperBilling.showAmount(item.data.price)
        }
        if(type === 'desc') {
          label = ''
          if(item.data.desc !== undefined) {
            label = item.data.desc
          }
        }
      }
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderLogo() {
    if(global.spaceImage !== '') {
      return (
        <img src={global.spaceImage} alt={global.userBusiness}/>
      )
    } else {
      return (
        <img src="/img/logo.svg" alt="PT Mate"/>
      )
    }
  }

  
  renderAddress() {
    if(global.spaceAddress !== undefined) {
      var arr2 = global.spaceAddress.split('||')
      if(arr2.length > 3) {
        var address = arr2[0]+'\n'+arr2[1]+'\n'+arr2[2]+', '+arr2[3]
        return address
      }
    }
  }


  renderGST(type) {
    if(this.state.item.data.gst !== undefined) {
      if(type === 'label') {
        return <span>{lang.t('admin:inv.gst')}<br/></span>
      } else {
        return <span>{HelperBilling.showAmount(this.state.item.data.gst)}<br/></span>
      }
    }
  }


  renderDesc() {
    if(this.getProductInfo('desc') !== '') {
      return (
        <span style={{fontSize: 12, lineHeight: '10px', whiteSpace: 'pre-wrap'}}><br/>{this.getProductInfo('desc')}</span>
      )
    }
  }


  renderInvoice() {
    return (
      <div>
        <div className="print-header">
          {this.renderLogo()}
          <h2>{global.userBusiness}</h2>
        </div>
        <div className="col-12 print-data">
          <h2>{lang.t('admin:label.invoice')}</h2>
          <div className="print-info mt-30">
            <p>{global.spaceInvoice1 === '' ? this.renderAddress() : global.spaceInvoice1}</p>
            <p>{lang.t('admin:label.invoice')} {this.state.item.data.number}<br/>{Moment(this.state.item.data.date, 'X').locale(lang.language).format('D MMM YYYY')}<br/>{lang.t('admin:inv.due')} {Moment(this.state.item.data.due, 'X').locale(lang.language).format('D MMM YYYY')}</p>
            <p><strong>{lang.t('admin:inv.billto')}</strong><br/>{global.userName}<br/>{global.userPhone}</p>
          </div>
          <p className="big lft"><span>{lang.t('admin:inv.item')}</span><br/>{this.getProductInfo('name')}{this.renderDesc()}</p>
          <p className="big rgt" style={{textAlign: 'right'}}><span>{lang.t('admin:inv.price')}</span><br/>{global.curSym}{this.getProductInfo('price')}</p>
          <div className="clear"></div>
          <p className="big lft">{this.renderGST('label')}<strong>{lang.t('admin:inv.totaldue')}</strong></p>
          <p className="big rgt mb-30" style={{textAlign: 'right'}}>{this.renderGST('amount')}<strong>{global.curSym}{this.getProductInfo('price')}</strong></p>
          <div className="print-disclaimer left clear mt-30">
            <p className="small"><strong>{lang.t('admin:inv.payment.title')}</strong><br/>{lang.t('admin:inv.payment.text')}: https://ptmate.me/{global.uid}/admin/pay-invoice/{this.state.item.id}<br/>&nbsp;<br/>{global.spaceInvoice2}</p>
          </div>
        </div>
      </div>
    )
  }


  renderStatus() {
    if(this.state.item.data.status === 'paid') {
      return <p>{lang.t('admin:label.paid')}</p>
    } else if(this.state.item.data.status === 'void') {
      return <p>{lang.t('admin:label.void')}</p>
    } else if(this.state.item.data.status === 'open' && Moment(this.state.item.data.due, 'X') < Moment().add(-1, 'day')) {
      return <p style={{color: '#ff0000'}}>{lang.t('admin:label.overdue')}</p>
    } else {
      return <p>{lang.t('admin:label.open')}</p>
    }
  }


  renderPay() {
    if(this.state.item.data.status === 'open') {
      return (
        <Link to={'/'+global.uid+'/admin/pay-invoice/'+this.state.item.id} className="btn primary" style={{marginRight: 15}}>{lang.t('admin:button.paynow')}</Link>
      )
    } else {
      return (
        <span>&nbsp;</span>
      )
    }
  }


  renderBase() {
    if(this.state.item !== null) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <div className="col-12 noprint">
              <div className="box mt-20">
                <div className="info col-8 clear">
                  <label>{lang.t('admin:label.status')}</label>
                  {this.renderStatus()}
                </div>
                <div className="col-2">
                  {this.renderPay()}
                </div>
                <div className="col-2">
                  <button className="btn secondary" onClick={() => window.print()}>{lang.t('admin:button.print')}</button>
                </div>
                <div className="clear"></div>
              </div>
              <div className="sv-40"></div>
            </div>
            {this.renderInvoice()}
            <div className="clear sv-30"></div>
          </div>

          <div className="noprint">
            <div className="header">
              <div className="content">
                <Link to={'/'+global.uid+'/admin/payments'} className="back lft mr-10">
                  <div className="circle"></div>
                  <div className="arrow"></div>
                </Link>
                <h2>{lang.t('admin:label.invoice')} {this.state.item.data.number}</h2>
              </div>
            </div>
            <Navigation active='admin' />
          </div>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="noprint">
            <Navigation active='admin' />
          </div>
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common'])(withRouter(withAuthorizationMember(condition)(MemberInvoicePage)));