import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import Moment from 'moment';
import 'moment/locale/es';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberCalendarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: global.userName,
      done: false,
      num: global.num,
      selected: Moment().format('DD/MM/YYYY'),
      week: 'this',
      sessions: [],
      events: [],
      groups: global.clientGroups,
      active: global.userVerified
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id !== 'home' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.calendar')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('eventsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        this.configureData()
        document.title = lang.t('header:title.calendar')+' - '+global.userBusiness
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var tmp = []
    var tmp2 = []
    // Sessions
    for(var item of global.sessions) {
      if(item.data.group) {
        var add = false
        if(item.data.invitees === undefined || item.data.invitees.length === 0) {
          if(item.data.groups === undefined || item.data.groups.length === 0) {
            add = true
          }
        } else {
          if(item.data.invitees.indexOf(global.spaceClient) !== -1) {
            add = true
          }
        }
        if(item.data.groups !== undefined) {
          if(item.data.groups.length > 0) {
            for(var gr of this.state.groups) {
              if(item.data.groups.indexOf(gr.id) !== -1 && gr.data.clients.indexOf(global.spaceClient) !== -1) {
                add = true
              }
            }
          }
        }
        if(item.data.memberships !== undefined) {
          if(item.data.memberships.length > 0) {
            for(var sub of global.subscriptions) {
              if(item.data.memberships.indexOf(sub.id) !== -1) {
                add = true
              }
            }
            for(var cred of global.credits) {
              if(item.data.memberships.indexOf(cred.data.product) !== -1) {
                add = true
              }
            }
          }
        }
        if(Moment(item.data.timestamp, 'X') < Moment().add(item.data.duration, 'minutes')) {
          if(item.data.clients === undefined) {
            add = false
          } else {
            if(item.data.clients.indexOf(global.spaceClient) === -1) {
              add = false
            }
          }
        }
        if(global.loc !== '') {
          if(item.data.location !== global.loc) {
            add = false
          }
        }
        if(add) {
          tmp.push(item)
        }
      } else {
        if(item.data.client === global.spaceClient) {
          tmp.push(item)
        }
      }
    }
    // Events
    for(var item2 of global.events) {
      var add2 = false
      if(item2.data.invitees === undefined || item2.data.invitees.length === 0) {
        if(item2.data.groups === undefined || item2.data.groups.length === 0) {
          add2 = true
        }
      } else {
        if(item2.data.invitees.indexOf(global.spaceClient) !== -1) {
          add2 = true
        }
      }
      if(item2.data.groups !== undefined) {
        if(item2.data.groups.length > 0) {
          for(var gr2 of this.state.groups) {
            if(item2.data.groups.indexOf(gr2.id) !== -1 && gr2.data.clients.indexOf(global.spaceClient) !== -1) {
              add2 = true
            }
          }
        }
      }
      if(Moment(item.data.timestamp, 'X') < Moment().add(item.data.duration, 'minutes')) {
        if(item.data.clients === undefined) {
          add = false
        } else {
          if(item.data.clients.indexOf(global.spaceClient) === -1) {
            add = false
          }
        }
      }
      if(add2) {
        tmp2.push(item2)
      }
    }

    if(!global.userVerified) {
      tmp = []
      tmp2 = []
    }
    this.setState({
      name: global.userName,
      sessions: tmp,
      events: tmp2,
      current: global.currentMonth,
      groups: global.clientGroups
    }, () => {
      this.selectWeek(this.state.week)
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && global.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  selectWeek(value) {
    var date = Moment().startOf('week').format('DD/MM/YYYY')
    if(value === 'next') {
      date = Moment().add(7, 'days').startOf('week').format('DD/MM/YYYY')
    }
    if(value === 'three') {
      date = Moment().add(14, 'days').startOf('week').format('DD/MM/YYYY')
    }
    if(value === 'four') {
      date = Moment().add(21, 'days').startOf('week').format('DD/MM/YYYY')
    }
    this.setState({
      week: value,
      selected: date,
    })
  }


  getSessionClass(item) {
    var color = 'primary'
    var icon = 'group'
    if(item.data.group && item.data.invitees !== undefined) {
      if(item.data.invitees.length > 0) {
        icon = 'lock'
      }
    }
    if(item.data.group && item.data.groups !== undefined) {
      if(item.data.groups.length > 0) {
        icon = 'lock'
      }
    }
    if(item.data.availability !== undefined) {
      if(item.data.availability && item.data.clients === undefined) {
        color = 'grey'
        icon = 'session'
      }
      if(item.data.availability && item.data.clients !== undefined) {
        color = 'secondary'
        icon = 'session'
      }
    }
    if(!item.data.group) {
      color = 'secondary'
      icon = 'session'
    }
    if(item.data.title !== undefined) {
      color = 'quarternary'
      icon = 'event'
    }
    return [color, icon]
  }


  getSessionVirtual(item) {
    if(item.data.link !== undefined && item.data.link !== '') {
      return (
        <span>
          <img src='/img/cam.svg' alt='Virtual' className='show-light'/>
          <img src='/img/cam-dark.svg' alt='Virtual' className='show-dark'/>
        </span>
      )
    }
  }


  getSessionLocation(item) {
    if(item.data.locationName !== undefined && item.data.locationName !== '') {
      return <span><br/>{item.data.locationName}</span>
    }
  }


  renderStatus(item) {
    if(Moment(item.data.timestamp, 'X').add(item.data.duration, 'minutes') < Moment() && item.data.attendance === 3) {
      if(!item.data.group) {
        return <div className="status green">{lang.t('calendar:label.attended')}</div>
      } else {
        if(item.data.clients !== undefined) {
          if(item.data.clients.indexOf(global.spaceClient) !== -1) {
            return <div className="status green">{lang.t('calendar:label.attended')}</div>
          }
        }
      }
    } else if(Moment(item.data.timestamp, 'X').add(item.data.duration, 'minutes') > Moment() && item.data.group) {
      if(item.data.clients !== undefined) {
        if(item.data.clients.indexOf(global.spaceClient) !== -1) {
          return <div className="status green prominent">{lang.t('calendar:label.bookedin')}</div>
        } else if(item.data.waiting !== undefined) {
          if(item.data.waiting.indexOf(global.spaceClient) !== -1) {
            return <div className="status yellow">{lang.t('calendar:label.waiting')}</div>
          }
        }
      }
    }
  }


  getSessionsDay(day, starttime, endtime) {
    if(!this.state.loading) {
    var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week').format('DD/MM/YYYY')
    var array = []
    var date = Moment(week, 'DD/MM/YYYY').add(day, 'days').format('DD/MM/YYYY')
    //var time = '12:00'
    var start = Moment(date+' '+starttime, 'DD/MM/YYYY HH:mm')
    var end = Moment(date+' '+endtime, 'DD/MM/YYYY HH:mm')
    for(var item of this.state.sessions) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < end && item.data.availability === undefined) {
        array.push(item)
      }
    }
    array.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    var array2 = []
    for(var item2 of this.state.sessions) {
      if(Moment(item2.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item2.data.date, 'DD/MM/YYYY HH:mm') < end && item2.data.availability) {
        if(item2.data.clients === undefined) {
          array2.push(item2)
        } else {
          if(item2.data.clients.length === 0) {
            array2.push(item2)
          }
        }
      }
    }
    array2.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    var array3 = []
    for(var item3 of this.state.events) {
      if(Moment(item3.data.date, 'DD/MM/YYYY HH:mm') >= start && Moment(item3.data.date, 'DD/MM/YYYY HH:mm') < end) {
        array3.push(item3)
        
      }
    }
    array3.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    return (
      <div>
        {array.map(item => (
          <div className="cal-session" key={item.id} onClick={(e) => this.clickSession(item)}>
            {this.renderStatus(item)}
            <div className={'icon clear '+this.getSessionClass(item)[0]}>
              <div className={'inner '+this.getSessionClass(item)[1]}></div>
            </div>
            <p className="lft"><strong>{this.getSessionName(item)}{this.getSessionVirtual(item)}</strong><br/>{Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')} - {Cal.getDuration(item.data.duration)}{this.getSessionLocation(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
        {array2.map(item => (
          <div className="cal-session" key={item.id} onClick={(e) => this.clickSession(item)}>
            {this.renderStatus(item)}
            <div className={'icon clear '+this.getSessionClass(item)[0]}>
              <div className={'inner '+this.getSessionClass(item)[1]}></div>
            </div>
            <p className="lft"><strong>{lang.t('calendar:label.available')} {item.data.clients === undefined ? '' : ' ('+this.getClientNameAvail(item)+')'}{this.getSessionVirtual(item)}</strong><br/>{Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')} - {Cal.getDuration(item.data.duration)}{this.getSessionLocation(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
        {array3.map(item => (
          <div className="cal-session" key={item.id} onClick={(e) => this.clickEvent(item)}>
            {this.renderBooked(item)}
            <div className={'icon clear '+this.getSessionClass(item)[0]}>
              <div className={'inner '+this.getSessionClass(item)[1]}></div>
            </div>
            <p className="lft"><strong>{item.data.title}</strong><br/>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')} - {Cal.getDuration(item.data.duration)}{this.getSessionLocation(item)}</p>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )}
  }


  getClientNameAvail(item) {
    var label = ""
    return label
  }


  getSessionName(item) {
    var label = lang.t('calendar:label.group')
    if(item.data.group && item.data.client !== '') {
      label = item.data.client
    }
    if(!item.data.group) {
      label = lang.t('calendar:label.pt')
    }
    if(item.data.link !== '') {
      label += ' ('+lang.t('calendar:label.virtual')+')'
    }
    return label
  }


  clickSession(item) {
    this.props.history.push('/'+global.uid+'/session/'+item.id)
  }


  clickEvent(item) {
    this.props.history.push('/'+global.uid+'/event/'+item.id)
  }



  // Display stuff ------------------------------------------------------------



  renderBooked(item) {
    if(item.data.clients !== undefined) {
      if(item.data.clients.indexOf(global.spaceClient) !== -1) {
        return (
          <div className="status green">{lang.t('calendar:label.attended')}</div>
        )
      }
    }
  }


  getWeekDate(num) {
    var label = '1 Jan'
    var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week')
    label = Moment(week).add(num, 'days').locale(lang.language).format('D MMM')
    if(Moment(week).add(num, 'days').format('D MM YYYY') === Moment().format('D MM YYYY')) {
      label = lang.t('calendar:label.today2')
    }
    return label
  }


  getDayClass(type, day) {
    var label = ''
    if(type === 'week') {
      var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week')
      if(Moment(week).add(day, 'days').format('D MM YYYY') === Moment().format('D MM YYYY')) {
        label = lang.t('calendar:label.today')
      }
    } else if(type === 'month') {
      var second = Moment().startOf('month').add(this.state.current-1, 'months').format('MM/YYYY')
      var dy2 = Moment(day+'/'+second, 'D/MM/YYYY').format('D MM YYYY')
      if(dy2 === Moment().format('D MM YYYY')) {
        label = lang.t('calendar:label.today')
      }
    }
    return label
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.calendar')}</h2>
              <div className={this.state.week === 'this' ? 'tab active' : 'tab'} onClick={() => this.selectWeek('this')}>
                <p>{lang.t('nav:cal.thisweek')}</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.week === 'next' ? 'tab active' : 'tab'} onClick={() => this.selectWeek('next')}>
                <p>{lang.t('nav:cal.nextweek')}</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.week === 'three' ? 'tab active' : 'tab'} onClick={() => this.selectWeek('three')}>
                <p>{lang.t('calendar:label.week')} {Moment().startOf('week').add(14, 'days').locale(lang.language).format('D MMM')} - {Moment().startOf('week').add(20, 'days').locale(lang.language).format('D MMM')}</p>
                <div className="bg"></div>
              </div>
              <div className={this.state.week === 'four' ? 'tab active' : 'tab'} onClick={() => this.selectWeek('four')}>
              <p>{lang.t('calendar:label.week')} {Moment().startOf('week').add(21, 'days').locale(lang.language).format('D MMM')} - {Moment().startOf('week').add(27, 'days').locale(lang.language).format('D MMM')}</p>
                <div className="bg"></div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="listheader">
            <h3>{Moment(this.state.selected, 'DD/MM/YYYY').locale(lang.language).format('D MMM')} - {Moment(this.state.selected, 'DD/MM/YYYY').add(6, 'days').locale(lang.language).format('D MMM')}</h3>
            <div className="clear"></div>
          </div>

          <div className="calmain">
            <div style={{minWidth: '1200px'}}>
              <div className="calendar">
                <div className={'weekday lft cal-top '+this.getDayClass('week', 0)}><h3>{lang.t('calendar:label.mon')} <span>{this.getWeekDate(0)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 1)}><h3>{lang.t('calendar:label.tue')} <span>{this.getWeekDate(1)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 2)}><h3>{lang.t('calendar:label.wed')} <span>{this.getWeekDate(2)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 3)}><h3>{lang.t('calendar:label.thu')} <span>{this.getWeekDate(3)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 4)}><h3>{lang.t('calendar:label.fri')} <span>{this.getWeekDate(4)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 5)}><h3>{lang.t('calendar:label.sat')} <span>{this.getWeekDate(5)}</span></h3></div>
                <div className={'weekday lft cal-top '+this.getDayClass('week', 6)}><h3>{lang.t('calendar:label.sun')} <span>{this.getWeekDate(6)}</span></h3></div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(0, '00:00', '12:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(1, '00:00', '12:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(2, '00:00', '12:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(3, '00:00', '12:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(4, '00:00', '12:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(5, '00:00', '12:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.morning')}</h4>
                    {this.getSessionsDay(6, '00:00', '12:00')}
                  </div>
                </div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(0, '12:00', '14:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(1, '12:00', '14:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(2, '12:00', '14:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(3, '12:00', '14:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(4, '12:00', '14:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(5, '12:00', '14:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.midday')}</h4>
                    {this.getSessionsDay(6, '12:00', '14:00')}
                  </div>
                </div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(0, '14:00', '18:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(1, '14:00', '18:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(2, '14:00', '18:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(3, '14:00', '18:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(4, '14:00', '18:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(5, '14:00', '18:00')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.afternoon')}</h4>
                    {this.getSessionsDay(6, '14:00', '18:00')}
                  </div>
                </div>
                <div className="clear"></div>

                <div style={{display: 'flex'}}>
                  <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(0, '18:00', '23:59')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(1, '18:00', '23:59')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(2, '18:00', '23:59')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(3, '18:00', '23:59')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(4, '18:00', '23:59')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(5, '18:00', '23:59')}
                  </div>
                  <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                    <h4>{lang.t('calendar:label.evening')}</h4>
                    {this.getSessionsDay(6, '18:00', '23:59')}
                  </div>
                </div>
                <div className="clear"></div>

              </div>
              <div className="clear sv-40"></div>
            </div>
          </div>
        </div>
        <Navigation active='calendar' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','calendar'])(withRouter(withAuthorizationMember(condition)(MemberCalendarPage)));