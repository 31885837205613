import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperCal from '../../helper/Calendar';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      name: global.userName,
      exercises: global.exercises,
      session: '',
      id: '',
      program: null,
      blocks: [],
      mins: [],
      secs: [],
      changed: false,
      isTraining: false,
      current: 0,
      best: global.clientBest,
      showOverlayVideo: false,
      hiddenVideo: 'hidden',
      margin: 0,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var sess = arr[arr.length-1]
    document.title = lang.t('header:title.sessionprogram')+' - '+global.userBusiness

    if(id !== 'results' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    
    window.scrollTo(0, 0)

    this.setState({
      done: true,
      session: sess,
    }, () => {
      this.configureData()
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.sessionprogram')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var program = null
    var tmp1 = []
    var tmp2 = []
    var tblk = []
    for(var j=0; j<60; j++) {
      tmp1.push(j+' min')
      tmp2.push(j+' '+lang.t('training:label.sec'))
    }

    var user = ''
    var id = ''
    var session = this.state.session
    var date = ''
    var list = global.sessions
    var isTraining = false
    var attendees = []
    var current = 0

    for(var s2 of global.sessionsTraining) {
      if(s2.id === session) {
        list = global.sessionsTraining
        isTraining = true
      }
    }

    for(var s3 of global.archiveTraining) {
      if(s3.id === session) {
        list = global.archiveTraining
        isTraining = true
      }
    }

    for(var item of list) {
      if(item.id === session) {
        program = item.program[0]
        id = item.program[0].id
        program = item.program[0]
        date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('lll')
        if(item.data.group) {
          attendees = item.data.clients
          for(var ai=0; ai<item.data.clients.length; ai++) {
            if(item.data.clients[ai] === global.spaceClient) {
              current = ai
            }
          }
        }

        for(var block of item.program[0].blocks) {
          var min = parseInt(block.data.rounds/60)
          var rmin = parseInt(block.data.rounds/60)
          var rsec = parseInt(block.data.rounds)-(rmin*60)
          if(block.data.timeRes !== undefined && block.data.timeRes !== null) {
            rmin = parseInt(block.data.timeRes/60)
            rsec = block.data.timeRes-(rmin*60)
          }
          var rming = []
          var rsecg = []
          var arrt = []
          var cycles = 1
          var simple = false

          if(block.data.cycles !== undefined) {
            cycles = block.data.cycles
          }
          if(item.data.group) {
            if(block.data.timeResGroup !== undefined && block.data.timeResGroup !== null) {
              arrt = block.data.timeResGroup
            }
            if(arrt.length === 0) {
              for(var att1 of attendees) {
                rming.push(0)
                rsecg.push(0)
              }
            } else {
              for(var tme of arrt) {
                rming.push(parseInt(tme/60))
                rsecg.push(tme-(parseInt(tme/60)*60))
              }
            }
          }

          if(block.data.simple !== undefined) {
            simple = true
          }

          var ex = []
          for(var i=0; i<block.data.exName.length; i++) {
            var min1 = parseInt(block.data.exWork[i]/60)
            var min2 = parseInt(block.data.exRest[i]/60)
            var min3 = parseInt(block.data.exResWeight[i]/60)

            var arr1 = []
            var arr2 = []
            var m = []
            var s = []
            

            var rreps = ""
            var rweight = ""
            if(block.data.exRepsRounds !== undefined) {
              if(block.data.exRepsRounds.length > i) {
                rreps = block.data.exRepsRounds[i]
              }
            }
            if(block.data.exWeightRounds !== undefined) {
              if(block.data.exWeightRounds.length > i) {
                rweight = block.data.exWeightRounds[i]
              }
            }

            if(item.data.group) {
              arr1 = block.data.exResRepsGroup[i].split('-')
              if(arr1[0] === '' || arr1[0] === undefined || isNaN(arr1[0])) {
                arr1.splice(0,1)
              }
              arr2 = block.data.exResWeightGroup[i].split('-')
              if(arr2[0] === '' || arr2[0] === undefined || isNaN(arr2[0])) {
                arr2.splice(0,1)
              }

              if(arr1.length === 0) {
                for(var at1 of attendees) {
                  arr1.push(0)
                }
              } 
              if(arr2.length === 0) {
                for(var at2 of attendees) {
                  arr2.push(0)
                }
              }
              for(var im of arr2) {
                var mins = parseInt(im/60)
                m.push(parseInt(im/60))
                s.push(parseInt(im)-(mins*60))
              }
            }

            var exWeightType = []
            for(var ei of block.data.exName) {
              exWeightType.push('per')
            }
            if(block.data.exWeightType !== undefined) {
              exWeightType = block.data.exWeightType
            }

            var rrr = ''
            if(block.data.exResRepsRounds !== undefined) {
              rrr = block.data.exResRepsRounds[i]
            }

            var wrr = ''
            if(block.data.exResWeightRounds !== undefined) {
              wrr = block.data.exResWeightRounds[i]
            }

            var unit = ''
            if(block.data.exUnits !== undefined) {
              if(block.data.exUnits.length > i) {
                unit = block.data.exUnits[i]
              }
            }

            var notes = ''
            if(block.data.exNotes !== undefined) {
              if(block.data.exNotes.length > i) {
                notes = block.data.exNotes[i]
              }
            }
            
            ex.push({
              id: block.data.exId[i],
              name: block.data.exName[i],
              tool: block.data.exTool[i],
              image: block.data.exImage[i],
              cat: block.data.exCat[i],
              type: block.data.exType[i],
              reps: block.data.exReps[i],
              repsRound: rreps,
              resReps: block.data.exResReps[i],
              resRepsRound: rrr,
              resRepsGroup: arr1,
              weight: block.data.exWeight[i],
              weightRound: rweight,
              resWeight: block.data.exResWeight[i],
              resWeightGroup: arr2,
              resWeightRound: wrr,
              weightType: exWeightType[i],
              work: block.data.exWork[i],
              rest: block.data.exRest[i],
              wmin: min1,
              wsec: block.data.exWork[i]-(min1*60),
              rmin: min2,
              rsec: block.data.exRest[i]-(min2*60),
              resMin: min3,
              resSec: block.data.exResWeight[i]-(min3*60),
              resMinGroup: m,
              resSecGroup: s,
              unit: unit,
              notes: notes,
            })
          }
          tblk.push({
            type: block.data.type,
            cat: block.data.cat,
            rounds: block.data.rounds,
            cycles: cycles,
            emom: block.data.emom,
            notes: block.data.notes,
            resNotes: block.data.notesRes,
            exercises: ex,
            min: min,
            sec: block.data.rounds-(min*60),
            resMin: rmin,
            resSec: rsec,
            resMinGroup: rming,
            resSecGroup: rsecg,
            show: false,
            simple: simple
          })
        }
      }
    }

    this.setState({
      id: id,
      session: session,
      program: program,
      date: date,
      mins: tmp1,
      secs: tmp2,
      exercises: global.exercises,
      blocks: tblk,
      user: user,
      isTraining: isTraining,
      attendees: attendees,
      best: global.clientBest,
      current: current
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  setTimeMin(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resMin = event.target.value
    } else {
      tmp[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSec(event, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[index].resSec = event.target.value
    } else {
      tmp[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeReps(event, num, index) {
    var tmp = this.state.blocks
    var val =  parseInt(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resReps = val
    } else {
      tmp[num].exercises[index].resRepsGroup[this.state.current] = val
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeRepsRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-]/g, "")
    val = val.replace('--', '-')
    var reps = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        reps += parseInt(a)
      }
      
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resReps = reps
      tmp[num].exercises[index].resRepsRound = val
    } else {
      tmp[num].exercises[index].resRepsGroup[this.state.current] = reps
      var vals = ''
      var ar2 = tmp[num].exercises[index].resRepsRound.split('|')
      ar2[this.state.current] = val
      for(var a2 of ar2) {
        vals += a2+'|'
      }
      tmp[num].exercises[index].resRepsRound = vals
    }
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeight(event, num, index) {
    var tmp = this.state.blocks
    var val = parseFloat(event.target.value)
    if(event.target.value === '') {
      val = 0
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resWeight = val
    } else {
      tmp[num].exercises[index].resWeightGroup[this.state.current] = val
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  onChangeWeightRound(event, num, index) {
    var tmp = this.state.blocks
    var val = event.target.value
    var vals = ''
    if(val === '') { val = '0' }
    val = val.replace(/[^0-9-.]/g, "")
    val = val.replace('--', '-')
    val = val.replace('.-', '-')
    val = val.replace('. ', '')
    val = val.replace('..', '.')
    var weight = 0
    var ar = val.split('-')
    for(var a of ar) {
      if(a !== '') {
        if(parseFloat(a) > weight) {
          weight = parseFloat(a)
        }
      }
      
    }
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resWeight = weight
      tmp[num].exercises[index].resWeightRound = val
    } else {
      tmp[num].exercises[index].resWeightGroup[this.state.current] = weight
      vals = ''
      var ar2 = tmp[num].exercises[index].resWeightRound.split('|')
      ar2.splice(ar2.length-1,1)
      ar2[this.state.current] = val
      for(var a2 of ar2) {
        vals += a2+'|'
      }
      tmp[num].exercises[index].resWeightRound = vals
    }
    
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeMinEx(event, num, index) {
    var tmp = this.state.blocks
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resMin = event.target.value
    } else {
      tmp[num].exercises[index].resMinGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setTimeSecEx(event, num, index) {
    var tmp = this.state.blocks 
    if(this.state.attendees.length === 0) {
      tmp[num].exercises[index].resSec = event.target.value
    } else {
      tmp[num].exercises[index].resSecGroup[this.state.current] = event.target.value
    }
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  toggleNotes(index) {
    var tmp = this.state.blocks
    tmp[index].show = !tmp[index].show
    this.setState({
      blocks: tmp,
    })
  }


  setNotes(event, index) {
    var tmp = this.state.blocks
    tmp[index].resNotes = event.target.value
    this.setState({
      blocks: tmp,
      changed: true,
    })
  }


  setAR1(event, index) {
    var tmp = this.state.blocks
    var reps = 0
    if(tmp.tmp2 !== undefined && tmp.tmp1 !== '') {
      reps = parseInt(tmp.tmp2)
    }
    for(var ex of tmp[index].exercises) {
      if(this.state.attendees.length === 0) {
        ex.resReps = ex.reps*parseInt(event.target.value)
      } else {
        ex.resRepsGroup[this.state.current] = ex.reps*parseInt(event.target.value)
      }
      if(reps > 0) {
        if(reps > ex.reps) {
          if(this.state.attendees.length === 0) {
            ex.resReps += ex.reps
          } else {
            ex.resRepsGroup[this.state.current] += ex.reps
          }
          reps -= ex.reps
        } else {
          if(this.state.attendees.length === 0) {
            ex.resReps += reps
          } else {
            ex.resRepsGroup[this.state.current] += reps
          }
          reps = 0
        }
      }
    }
    tmp.tmp1 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }


  setAR2(event, index) {
    var tmp = this.state.blocks
    var rounds = 0
    var reps = 0
    if(event.target.value !== '') {
      reps = parseInt(event.target.value)
    }
    if(tmp.tmp1 !== undefined && tmp.tmp1 !== '') {
      rounds = parseInt(tmp.tmp1)
    }
    for(var ex of tmp[index].exercises) {
      //ex.resReps = ex.reps*rounds
      if(this.state.attendees.length === 0) {
        ex.resReps = ex.reps*rounds
      } else {
        ex.resRepsGroup[this.state.current] = ex.reps*rounds
      }
      if(reps > 0) {
        if(reps > ex.reps) {
          if(this.state.attendees.length === 0) {
            ex.resReps += ex.reps
          } else {
            ex.resRepsGroup[this.state.current] += ex.reps
          }
          reps -= ex.reps
        } else {
          if(this.state.attendees.length === 0) {
            ex.resReps += reps
          } else {
            ex.resRepsGroup[this.state.current] += reps
          }
          reps = 0
        }
      }
    }
    tmp.tmp2 = event.target.value
    this.setState({
      blocks: tmp,
      changed: true
    })
  }



  // Update results ------------------------------------------------------------



  updateResults() {
    var tmp  = this.state.program.blocks
    var parent = global.uid
    if(this.state.isTraining) {
      parent = global.uidUser
    }
    for(var i=0; i<this.state.blocks.length; i++) {
      var block = this.state.blocks[i]
      var weight = []
      var reps = []
      var repsr = []
      var rarr = []
      var warr = []
      var tgarr = []
      var weightr = []
      for(var ex of block.exercises) {
        reps.push(ex.resReps)

        var rrl = ex.resRepsRound
        if(rrl[0] === '-') {
          rrl = rrl.substring(1, rrl.length)
        }
        if(rrl[rrl.length-1] === '-') {
          rrl = rrl.substring(0, rrl.length-1)
        }

        var wrl = ex.resWeightRound
        if(wrl[0] === '-') {
          wrl = wrl.substring(1, wrl.length)
        }
        if(wrl[wrl.length-1] === '-') {
          wrl = wrl.substring(0, wrl.length-1)
        }

        if(this.state.attendees.length > 0) {
          var ar1 = rrl.split('|')
          rrl = ''
          for(var a1 of ar1) {
            if(a1[0] === '-') {
              a1 = a1.substring(1, a1.length)
            }
            if(a1[a1.length-1] === '-') {
              a1 = a1.substring(0, a1.length-1)
            }
            if(a1 !== '') {
              rrl += a1+'|'
            }
          }
          var ar2 = wrl.split('|')
          wrl = ''
          for(var a2 of ar2) {
            if(a2[0] === '-') {
              a2 = a2.substring(1, a2.length)
            }
            if(a2[a2.length-1] === '-') {
              a2 = a2.substring(0, a2.length-1)
            }
            if(a2 !== '') {
              wrl += a2+'|'
            }
          }
        }
        repsr.push(rrl)
        weightr.push(wrl)

        if(this.state.attendees.length > 0) {
          var rass = ''
          for(var i1=0; i1<this.state.attendees.length; i1++) { 
            rass += '-'+ex.resRepsGroup[i1]
          }
          var trass = rass.replace("undefined", "0")
          rass = trass.substring(1,trass.length)
          rarr.push(rass)
        }
        if(ex.tool !== 6 && ex.tool !== 7) {
          weight.push(ex.resWeight)
          if(this.state.attendees.length > 0) {
            var wass1 = ''
            for(var i2=0; i2<this.state.attendees.length; i2++) {
              wass1 += '-'+ex.resWeightGroup[i2]
            }
            var wasf1 = wass1.replace("undefined", "0")
            wasf1 = wasf1.substring(1,wasf1.length)
            warr.push(wasf1)
          }
        } else {
          weight.push(parseInt(ex.resMin)*60+parseInt(ex.resSec))
          if(this.state.attendees.length > 0) {
            var wass2 = ''
            for(var i3=0; i3<this.state.attendees.length; i3++) {
              wass2 += '-'+(parseInt(ex.resMinGroup[i3])*60+parseInt(ex.resSecGroup[i3]))
            }
            var wasf2 = wass2.replace("undefined", "0");
            warr.push(wasf2)
          }
        }
        if(this.state.attendees.length === 0) {
          this.updateBest(ex)
        } else {
          this.updateBestGroup(ex)
        }
        
      }

      if(block.type === 0 || block.type === 5) {
        if(this.state.attendees.length > 0) {
          for(var bi1=0; bi1<this.state.attendees.length; bi1++) {
            tgarr.push((parseInt(block.resMinGroup[bi1])*60+parseInt(block.resSecGroup[bi1])))
          }
          tmp[i].data.timeResGroup = tgarr
        }
      }
      tmp[i].data.notesRes = block.resNotes
      tmp[i].data.timeRes = parseInt(block.resMin)*60+parseInt(block.resSec)
      tmp[i].data.exResWeight = weight
      tmp[i].data.exResWeightGroup = warr
      tmp[i].data.exResReps = reps
      tmp[i].data.exResRepsGroup = rarr

      tmp[i].data.exResRepsRounds = repsr
      tmp[i].data.exResWeightRounds = weightr

      Firebase.database().ref('/sessions/'+parent+'/'+this.state.session+'/workout/'+this.state.program.id+'/blocks/'+tmp[i].id).update(
        tmp[i].data
      ).then((data)=>{
        EventEmitter.dispatch('showMessage', lang.t('message:results'))
        setTimeout(() => {
          this.setState({
            changed: false
          })
        }, 100);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }


  updateBest(ex) {
    var update = true
    var final = ex.resWeight
    var actual = ex.resWeight
    var per = 0
    var tmp = this.state.best

    for(var item of tmp) {
      if(item.id === ex.id) {
        if(ex.tool !== 6 && ex.tool !== 7) {
          if(ex.weight > 0 && ex.weightType === 'per') {
            final = ex.resWeight*(100/ex.weight)
            per = ex.weight
          }
          
          var rval = item.data.value
          var rtype = ""
          if(item.data.type !== undefined) {
            rtype = item.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = item.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = item.data.value*global.lbsDown
          }
          if(rval > final) {
            update = false
          }
        }
        if(item.data.value < ex.resWeight && (ex.tool === 6 || ex.tool === 7)) {
          update = false
        }
        if(update) {
          item.data.value = final
          this.setState({
            best: tmp
          })
        }
        
      }
      
    }
    var unit = ''
    if(ex.unit !== undefined) {
      unit = ex.unit
    }
    if(update && ex.tool !== 0 && ex.tool !== 9) {
      Firebase.database().ref('/records/'+global.uidUser+'/'+ex.id).update({
        date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
        id: ex.id,
        name: ex.name,
        tool: ex.tool,
        value: final,
        actual: actual,
        percent: per,
        unit: unit
      })
    }
  }


  updateBestGroup(ex) {
    
      var update = true
      var final = 0
      var actual = 0
      var per = 0
      var tmp = this.state.best

      if(ex.resWeightGroup !== undefined && ex.resWeightGroup.length > this.state.current) {
        final = parseFloat(ex.resWeightGroup[this.state.current])
        actual = parseFloat(ex.resWeightGroup[this.state.current])
        if(ex.weight > 0 && ex.weightType === 'per') {
          final = parseFloat(ex.resWeightGroup[this.state.current])*(100/ex.weight)
          per = ex.weight
        }
      }

      if(ex.tool === 0 && ex.tool === 9) {
        update = false
      }
      for(var cb of tmp) {
        if(cb.id === ex.id) {
          var rval = cb.data.value
          var rtype = ""
          if(cb.data.type !== undefined) {
            rtype = cb.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = cb.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = cb.data.value*global.lbsDown
          }
          if(rval > final || rval === final) {
            update = false
          }

          if(update) {
            cb.data.value = final
            this.setState({
              best: tmp
            })
          }
        }
      }

      if(final === 0) {
        update = false
      }
      var unit = ''
      if(ex.unit !== undefined) {
        unit = ex.unit
      }
      if(update) {
        Firebase.database().ref('/records/'+global.uidUser+'/'+ex.id).update({
          //date: Moment().format('DD/MM/YYYY HH:mm'),
          date: Moment(this.state.date, 'lll').format('DD/MM/YYYY HH:mm'),
          id: ex.id,
          name: ex.name,
          tool: ex.tool,
          value: final,
          actual: actual,
          percent: per,
          unit: unit
        })
      }
    //}
  }



  // Video overlay ------------------------------------------------------------



  showOverlayVideo() {
    this.setState({showOverlayVideo: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenVideo: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayVideo() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenVideo: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayVideo: false});
    }, 500);
  }


  renderPlayer() {
    var arr = this.state.program.data.video.split('/')
    if(this.state.program.data.video.indexOf('youtu') !== -1) {
      var link = arr[arr.length-1]
      if(arr[arr.length-1].indexOf('?')) {
        var ar1 = arr[arr.length-1].split('=')
        var ar11 = ar1[ar1.length-2].split('&')
        link = ar11[0]
      }
      return (
        <iframe width="100%" height="470" src={'https://www.youtube.com/embed/'+link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      )
    } else {
      var ar2 = arr[arr.length-1].split('?')
      return (
        <iframe src={'https://player.vimeo.com/video/'+ar2[0]+'?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} frameborder="0" width="100%" height="470" title="Video player"></iframe>
      )
    }
  }


  renderOverlayVideo() {
    if(this.state.showOverlayVideo) {
      return (
        <div className={'overlay '+this.state.hiddenVideo}>
          <div className="box mainvideo auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h3>{lang.t('common:button.video')}</h3>
            <button className="btn tertiary small close" onClick={() => this.hideOverlayVideo()}>{lang.t('modal:common.close')}</button>
            <div className="clear"></div>
            {this.renderPlayer()}
          </div>
        </div>
      )
    }
  }



  // Movements stuff ------------------------------------------------------------



  renderImage(img) {
    if(img === '') {
      return (
        <div className="image"></div>
      )
    } else if(img.indexOf('adm-') !== -1) {
      return (
        <div className="image" style={{backgroundImage: 'url(/img/exercises/'+img+'.jpg)'}}></div>
      )
    } else {
      return (
        <div className="image" style={{backgroundImage: 'url('+img+')'}}></div>
      )
    }
  }


  getSetTitle(block) {
    var label = lang.t('training:prog.set')
    if(block.exercises.length > 1) {
      label = lang.t('training:prog.dropset')
      var id = ''
      for(var ex of block.exercises) {
        if(ex.id !== id && id !== '') {
          label = lang.t('training:prog.superset')
        }
      }
    }
    return label
  }


  renderBlockTypeStatic(item) {
    var label = ''
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1 || item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('training:prog.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('training:prog.round')
      }
      if(item.type === 1 && item.emom) {
        label += ' '+lang.t('training:prog.allmovements')
      }
      if(item.type === 2 && item.emom) {
        label += ' ('+HelperCal.getDurationMin(item.exercises[0].work)+' - '+HelperCal.getDurationMin(item.exercises[0].rest)+')'
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.getSetTitle(item)
      if(item.rounds > 1) {
        label += 's'
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('training:prog.rounds')+' - '+lang.t('training:prog.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderRepsTitleStatic(item, type, unit) {
    var label = lang.t('training:target.reps')
    if(type === 'result') {
      label = lang.t('training:target.repsdone')
    }
    if(unit !== '' && unit !== undefined && unit !== 'reps') {
      if(unit === 'dist') {
        label = lang.t('training:target.distance')
        if(type === 'result') {
          label = lang.t('training:target.distancedone')
        }
      }
      if(unit === 'cals') {
        label = lang.t('training:target.cal')
        if(type === 'result') {
          label = lang.t('training:target.caldone')
        }
      }
      if(unit === 'time') {
        label = lang.t('training:target.time')
        if(type === 'result') {
          label = lang.t('training:target.timedone')
        }
      }
    } else {
      if(item === 6 || item === 7) {
        label = lang.t('training:target.distance')
        if(type === 'result') {
          label = lang.t('training:target.distancedone')
        }
      }
    }
    return label
  }


  renderRepsStatic(item, num) {
    var label = item.reps
    var wlabel = ''
    if(item.reps === 0) {
      label = ''
    }

    if(item.weight !== 0 && item.weight !== 100 && item.weightType === 'per') {
      wlabel = ' '+lang.t('training:prog.at')+' '+item.weight+'% '+lang.t('training:prog.of1rm')
    }
    if(item.weight !== 0 && item.weight !== 100 && item.weightType !== 'per') {
      wlabel += ' '+lang.t('training:prog.with')+' '+item.weight+' '+item.weightType
    }
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 5) {
      label = ''
      if(item.repsRound !== "" && item.repsRound !== "0") {
        label = item.repsRound.replaceAll('-', ' - ')
      }
      if(item.weightRound !== "" && item.weightRound !== '0') {
        if(item.weightType === 'kg') {
          wlabel = ' '+lang.t('training:prog.with')+' '+item.weightRound.replaceAll('-', ' - ')+' kg'
        } else if(item.weightType === 'lb') {
          wlabel = ' '+lang.t('training:prog.with')+' '+item.weightRound.replaceAll('-', ' - ')+' lb'
        } else {
          wlabel = ' '+lang.t('training:prog.at')+' '+item.weightRound.replaceAll('-', ' - ')+' %'
        }
      }
    } 

    if(item.unit !== undefined && item.unit !== '') {
      if(item.unit === 'dist') {
        label += 'm'
      }
      if(item.unit === 'cals') {
        label += 'cal'
      }
      if(item.unit === 'time') {
        label += 's'
      }
    } else {
      if(item.tool === 6 || item.tool === 7) {
        label += 'm'
      }
      if(item.tool === 27) {
        label += 'cal'
      }
      if(item.tool === 28) {
        label += 's'
      }
    }
    
    return [label, wlabel]
  }


  renderRepField(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={item.resRepsRound} onChange={(val) => this.onChangeRepsRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resReps} onChange={(val) => this.onChangeReps(val, num, index)}/>
      )
    }
  }


  getResGR(item) {
    var label = ''
    if(item.resRepsRound !== undefined) {
      var ar = item.resRepsRound.split('|')
      if(ar.length > this.state.current) {
        label = ar[this.state.current]
      }
    }
    
    return label
  }


  renderRepFieldGroup(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={this.getResGR(item)} onChange={(val) => this.onChangeRepsRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resRepsGroup[this.state.current]} onChange={(val) => this.onChangeReps(val, num, index)}/>
      )
    }
  }


  renderReps(item, num, index) {
    if(this.state.attendees.length === 0) {
      return (
        <div className="col-3">
          <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
          {this.renderRepField(item, num, index)}
        </div>
      )
    } else {
      return (
        <div className="col-3">
          <label>{this.renderRepsTitleStatic(item.tool, 'result', item.unit, item.name)}</label>
          {this.renderRepFieldGroup(item, num, index)}
        </div>
      )
    }
  }


  renderWeightField(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={item.resWeightRound} onChange={(val) => this.onChangeWeightRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resWeight} onChange={(val) => this.onChangeWeight(val, num, index)}/>
      )
    }
  }


  getWeightGR(item) {
    var label = ''
    if(item.resWeightRound !== undefined && item.resWeightRound !== '') {
      var ar = item.resWeightRound.split('|')
      if(ar.length > this.state.current) {
        label = ar[this.state.current]
      }
    } else if(item.resWeightGroup !== undefined) {
      if(item.resWeightGroup.length > this.state.current) {
        label = item.resWeightGroup[this.state.current]
      }
    }
    return label
  }


  renderWeightFieldGroup(item, num, index) {
    if(this.state.blocks[num].type === 1 || this.state.blocks[num].type === 4 || this.state.blocks[num].type === 5) {
      return (
        <input type="text" className="light" value={this.getWeightGR(item)} onChange={(val) => this.onChangeWeightRound(val, num, index)}/>
      )
    } else {
      return (
        <input type="number" className="light" value={item.resWeightGroup[this.state.current]} onChange={(val) => this.onChangeWeight(val, num, index)}/>
      )
    }
  }


  renderStaticWeight(item) {
    var label = item.resWeight+' '+(global.spaceLbs ? 'lb' : 'kg')
    if(item.weightType !== 'per') {
      label = item.resWeight+' '+item.weightType
    }
    if(item.resWeightRound !== '') {
      var tmp = item.resWeightRound.substring(0, item.resWeightRound.length-1)
      var tmp = item.resWeightRound.substring(0, item.resWeightRound.length-1)
      label = tmp+' '+(global.spaceLbs ? 'lb' : 'kg')
      if(item.weightType !== 'per') {
        label = tmp+' '+item.weightType
      }
    }
    return label
  }


  renderWeight(item, num, index) {
    if(item.tool !== 0 && item.tool !== 6 && item.tool !== 7 && item.tool !== 9) {
      if(this.state.attendees.length === 0) {
        return (
          <div className="col-3">
            <label>{lang.t('training:label.weightused')} ({item.weightType !== 'per' ? item.weightType : (global.spaceLbs ? 'lb' : 'kg')})</label>
            {this.renderWeightField(item, num, index)}
          </div>
        )
      } else {
        return (
          <div className="col-3">
            <label>{lang.t('training:label.weightused')} ({item.weightType !== 'per' ? item.weightType : (global.spaceLbs ? 'lb' : 'kg')})</label>
            {this.renderWeightFieldGroup(item, num, index)}
          </div>
        )
      }
    }
  }



  // Display stuff ------------------------------------------------------------



  renderNotesStatic(item) {
    if(item.notes !== '') {
      return (
        <div className="clear" style={{paddingTop: 5}}>
          <span className="program-notes movement">{item.notes}</span>
        </div>
      )
    }
  }


  renderExerciseRest(block, index) {
    if(block.type === 2 && !block.emom && block.exercises[index].rest !== 0 && block.exercises[index].rest !== undefined && block.exercises[index].rest !== '') {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
            <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('training:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].rest))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderTimeStatic(block, index) {
    if((block.type === 1 || block.type === 2) && !block.emom) {
      return (
        <div className="col-2 program-time">
          <p>{HelperCal.getDurationMin(parseInt(block.exercises[index].work))}</p>
        </div>
      )
    }
  }


  renderExercisesStatic(num) {
    var block = this.state.blocks[num]
    if(!block.simple) {
      return (
        <div>
          {block.exercises.map((item, index) => (
            <div>
              <div className="box list simple mb-10" key={index}>
                <div className="col-10">
                  <div className="icon primary mr-10">
                    <div className={'inner '+global.exCatsIcons[block.cat]}></div>
                  </div>
                  <p className="lft"><strong>{this.renderRepsStatic(item, num)[0]} {item.name}</strong> {this.renderRepsStatic(item, num)[1]}</p>
                </div>
                {this.renderTimeStatic(block, index)}
                <div className="clear"></div>
                <div className="program-results">
                  {this.renderReps(item, num, index)}
                  {this.renderWeight(item, num, index)}
                  {this.renderNotesStatic(item)}
                  <div className="clear"></div>
                </div>
                <div className="clear"></div>
              </div>
              {this.renderExerciseRest(block, index)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderStaticNotes(item) {
    if(item.notes !== '') {
      return <p className={item.simple ? 'mb-20 pl-10' : 'small mb-20 pl-10'} style={{whiteSpace: 'pre-wrap'}}>{item.notes}</p>
    }
  }


  renderTime(item, index) {
    if(item.type === 5) {
      if(this.state.isTraining) {
        return (
          <div className="info">
            <label>{lang.t('training:label.completiontime')}</label>
            <p>{HelperCal.getDurationMin(item.resMin*60+item.resSec)}</p>
          </div>
        )
      } else {
        if(this.state.attendees.length === 0) {
          return (
            <div className="mb-20">
              <div className="form-item col-3">
                <label>{lang.t('training:label.completiontime')}</label>
                <select value={item.resMin} onChange={event => this.setTimeMin(event, index)}>
                  {this.state.mins.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <select value={item.resSec} onChange={event => this.setTimeSec(event, index)}>
                  {this.state.secs.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="clear"></div>
            </div>
          )
        } else {
          return (
            <div className="mb-20">
              <div className="col-3">
                <label>{lang.t('training:label.completiontime')}</label>
                <select value={item.resMinGroup[this.state.current]} onChange={event => this.setTimeMin(event, index)}>
                  {this.state.mins.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <label>&nbsp;</label>
                <select value={item.resSecGroup[this.state.current]} onChange={event => this.setTimeSec(event, index)}>
                  {this.state.secs.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="clear"></div>
            </div>
          )
        }
      }
    } else if(item.type === 0) {
      return (
        <div className="mb-20">
          <div className="col-3">
            <label>{lang.t('training:label.finishedrounds')}</label> 
            <input type="number" className="small"  onChange={(val) => this.setAR1(val, index)}/>
          </div>
          <div className="col-3">
            <label>{lang.t('training:label.reps')}</label>
            <input type="number" className="small" onChange={(val) => this.setAR2(val, index)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderStaticRest(block) {
    if(block.type === 4 && block.exercises[0].work !== 0 && block.exercises[0].work !== undefined) {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
          <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('training:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exercises[0].work))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBlockTitleStatic(item) {
    if(item.name !== '' && item.name !== undefined) {
      return item.name
    } else {
      return lang.t(global.exCats[item.cat])
    }
  }

  
  renderBlocksStatic() {
    return (
      <div>
        {this.state.blocks.map((item, index) => (
          <div key={index}>
            <div className="clear mb-50">
              <label>{this.renderBlockTitleStatic(item)}</label>
              <h3 className="clear lft mb-20">{lang.t(global.proTypes[item.type])} {this.renderBlockTypeStatic(item)}</h3>
              <div className="clear"></div>
              {this.renderTime(item, index)}
              {this.renderExercisesStatic(index)}
              {this.renderStaticRest(item)}
              {this.renderStaticNotes(item)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderBenchmarkPill() {
    if(this.state.program.data.benchmark) {
      return <p className="mb-20"><span className="pill primary">{lang.t('training:label.benchmark')}</span></p>
    }
  }


  renderButtonTop() {
    if(this.state.changed) {
      return (
        <div className="program-float">
          <p className="mb-10">{lang.t('training:label.unsaved')}</p>
          <button className="btn tertiary small width-12" onClick={() => this.updateResults()}>{lang.t('training:button.updateresults')}</button>
        </div>
      )
    }
  }


  renderButton() {
    if(this.state.changed) {
      return (
        <div>
          <div className="width-6">
            <button className="btn primary" onClick={() => this.updateResults()}>{lang.t('training:button.updateresults')}</button>
          </div>
          <div className="space-40"></div>
        </div>
      )
    }
  }


  renderButtonVideo() {
    if(this.state.program.data.video !== undefined && this.state.program.data.video !== "") {
      return (
        <button className="btn tertiary width-12" onClick={() => this.showOverlayVideo()}>{lang.t('common:button.video')}</button>
      )
    }
  }


  renderContent() {
    if(this.state.program !== null) {
      return (
        <div>
          <div className="program-header mb-60">
            <div className={'icon large lft mr-20 '+HelperCal.getColor(this.state.program.data.time)}>
              <p>{this.state.program.data.time}'</p>
            </div>
            <div className="main">
              <h2>{this.state.program.data.name}</h2>
              {this.renderBenchmarkPill()}
              <p className="mb-10">{this.state.program.data.desc}</p>
              {this.renderButtonVideo()}
            </div>
            <div className="clear"></div>
          </div>
          {this.renderBlocksStatic()}
          <div className="sv-30"></div>
          {this.renderButton()}
        </div>
      )
    }
  }


  renderSession() {
    var label = lang.t('calendar:label.results')
    if(this.state.session !== '') {
      for(var item of global.sessions) {
        if(item.id === this.state.session) {
          if(item.data.group) {
            if(item.data.client !== '') {
              label = item.data.client+' - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
            } else {
              label = lang.t('calendar:label.group')+' - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
            }
          } else {
            label = lang.t('calendar:label.pt')+' - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
          }
        }
      }
      for(var item2 of global.sessionsTraining) {
        if(item2.id === this.state.session) {
          label = lang.t('calendar:label.training')+' - '+Moment(item2.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
        }
      }
    }
    return label
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar mt-20">
          {this.renderContent()}
        </div>
        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/session/'+this.state.session} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{this.renderSession()}</h2>
          </div>
        </div>
        <Navigation active='calendar' />
        {this.renderButtonTop()}
        {this.renderOverlayVideo()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','common','calendar','modal','training','message'])(withRouter(withAuthorizationMember(condition)(MemberResultsPage)));