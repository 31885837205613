import React, { Component } from 'react';



class FormCard extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
      error: props.error ?? false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      values: props.values,
      error: props.error ?? false,
    }
  }


  /*onChange = event => {
    this.props.onChange(event.target.value)
  }*/
  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    //this.setState({valuesCard: tmp});
    this.props.onChange(tmp)
  };


  handleEnter(event){
    var num = 16
    if(event.target.id !== 'i1') {
      num = 2
    }
    if(event.target.value.length === num && event.target.id !== 'i4') {
      if(event.target.name === '1') {
        document.getElementById('i2').focus();
      }
      if(event.target.name === '2') {
        document.getElementById('i3').focus();
      }
      if(event.target.name === '3') {
        document.getElementById('i4').focus();
      }
      event.preventDefault();
    }
  }


  renderError(num) {
    var texts = ["Enter the card holder's name", 'Enter the card number', 'Enter the expiry month', 'Enter the expiry year', 'Enter the CVC']
    if(this.state.error[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  render() {
    return (
      <div>
        <div className="col-12">
          <div className="form-item">
            <label>Name on card*</label>
            <input type="text" value={this.state.values[0]} className={this.state.error[0] ? 'error': ''} name="0" onChange={this.onChange}/>
            {this.renderError(0)}
          </div>
          <div className="form-item">
            <label>Card number*</label>
            <input type="number" value={this.state.values[1]} className={this.state.error[1] ? 'error': ''} name="1" onChange={this.onChange} onKeyUp={(e)=>this.handleEnter(e)} id="i1"/>
            {this.renderError(1)}
          </div>
        </div>
        <div className="col-4 mb-20">
          <label>Expiry*</label>
          <input type="number" placeholder="MM" value={this.state.values[2]} className={this.state.error[2] ? 'error': ''} name="2" onChange={this.onChange} onKeyUp={(e)=>this.handleEnter(e)} id="i2" />
        </div>
        <div className="col-4 mb-20">
          <label>&nbsp;</label>
          <input type="number" placeholder="YY" value={this.state.values[3]} className={this.state.error[3] ? 'error': ''} name="3" onChange={this.onChange} onKeyUp={(e)=>this.handleEnter(e)} id="i3"/>
        </div>
        <div className="col-1">&nbsp;</div>
        <div className="col-3 mb-20">
          <label>CVC*</label>
          <input type="number" value={this.state.values[4]} className={this.state.error[4] ? 'error': ''} name="4" onChange={this.onChange} id="i4"/>
        </div>
        <div className="clear col-12">
          {this.renderError(2)}
          {this.renderError(3)}
          {this.renderError(4)}
        </div>
      </div>
    )
  }
}


export default FormCard;