import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Connector from '../../data/Connector';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import * as ROUTES from '../../constants/routes';

import Navigation from '../../components/Navigation';
import HelperEmptyImg from '../../helper/EmptyLarge';
import InputDate from '../../components/Form/input-date';
import InfoPrice from '../../components/Form/price';
import FormCard from '../../components/Form/card';
import Loader from '../../components/Form/loader';
import WidgetCard from '../../components/Widgets/card';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { AuthUserContext, withAuthorization } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberDebitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      products: [],
      product: null,
      productId: '',
      group: false,
      stype: '11',
      log: global.userLog,
      date: Moment().add(1, 'day').format('X'),
      loading: false,
      card: true,
      valuesCard: ['', '', '', '', ''],
      errorCard: [false, false, false, false, false],
      search: '',
      error: false,
      token: '',
      cancels: 'notset',
      cdate: Moment().add(90, 'days').format('X'),
      family: '',
      back: '/'+global.uid+'/admin/payments',
      prod: '',
      showModalConfirm: false,
      showModalStock: false,
    };
  }


  componentDidMount() {
    this._isMounted = true
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'admin' && id !== 'payment') {
      global.uid = id
    }
    var prod = ''
    if(this.props.location.pathname.indexOf('purchase') !== -1) {
      prod = arr[arr.length-1]
    }

    Connector.setUser()
    document.title = lang.t('header:title.membership')+' - '+global.userBusiness
    window.scrollTo(0, 0)
    
    this.setState({
      done: true,
      prod: prod
    })

    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('subsLoaded', (event) => {
      this.configureData()
      if(this.state.loading) {
        global.message = lang.t('message:membership')
        this.setState({
          loading: false
        })
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'payment');
        }, 1000);
        this.props.history.push('/'+global.uid+'/admin/payments')
      }
    })
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.membership')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var back = '/'+global.uid+'/admin/payments'
      for(var item of global.products) {
        if(item.data.type === 'subscription') {
          tmp.push(item)
        }
      }
      var pid = ''
      var tmpp = null
      if(this.state.prod !== '' && tmp.length !== 0) {
        pid = this.state.prod
        global.productlink = ''
        for(var t of tmp) {
          if(t.id === this.state.prod) {
            tmpp = t
          }
        }
      }
      var card = true
      if(global.userCard !== '') {
        card = false
      }
      this.setState({
        products: tmp,
        product: tmpp,
        productId: pid,
        back: back,
        card: card
      })
    }
  }


  configureLog() {
    if(this._isMounted) {
      this.setState({
        log: global.userLog
      }, () => {
        if(this.state.loading) {
          for(var item of this.state.log) {
            if(item.data.title === 'debiterror') {
              EventEmitter.dispatch('showMessageError', item.data.message);
              Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
              this.setState({
                loading: false,
                error: true
              })
            }
          }
        }
      })
    }
  }



  // Interactive ------------------------------------------------------------



  checkCreateDebit() {
    this.setState({errorCard: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.valuesCard[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.valuesCard[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.valuesCard[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.valuesCard[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.valuesCard[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({errorCard: tmp})

    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      stock = this.state.product.data.stock-1
    }

    if(this.state.product.data.stock !== 0) {
      if(passed) {
        // Execute payment
        this.setState({
          loading: true,
        })
        var group = 'no'
        if(this.state.product.data.stype === 'group') {
          group = 'yes'
        } else if(this.state.product.data.stype === 'both') {
          group = 'both'
        }

        var sessions = 0
        if(this.state.product.data.sessions !== undefined) {
          sessions = this.state.product.data.sessions
        }
        var sessions11 = 0
        if(this.state.product.data.sessions11 !== undefined) {
          sessions11 = this.state.product.data.sessions11
        }

        var email = global.userEmail

        var ds1 = Moment(this.state.date, 'X').format('DD/MM/YYYY')
        var date = Moment(ds1+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

        if(Moment(date, 'X') < Moment()) {
          var start = Moment().add(1, 'day').format('DD/MM/YYYY')
          date = Moment(start+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
        }

        var cdate = this.state.cdate
        if(Moment(cdate, 'X') < Moment(date, 'X')) {
          cdate = Moment(date, 'X').add(1, 'days').format('X')
        }
        var ds2 = Moment(cdate, 'X').format('DD/MM/YYYY')
        cdate = Moment(ds2+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

        if(global.userCard !== '') {
          var callFunction3 = Firebase.functions().httpsCallable('connectedManageClientCard');
          callFunction3({type: 'delete', account: global.userStripeConnect, customer: global.userStripe, card: global.userCard, client: global.spaceClient, uid: global.uid}).then(function(result) {});
        }

        var count = 1
        if(this.state.product.data.interval !== undefined && this.state.product.data.interval !== 1) {
          count = this.state.product.data.interval
        }

        if(global.userStripe !== '') {
          // Create card only
          var callFunction = Firebase.functions().httpsCallable('connectedCreateSubscriptionV2');
          callFunction({type: 'card', account: global.userStripeConnect, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], customer: global.userStripe, client: global.spaceClient, product: this.state.product.data.product, plan: this.state.product.data.sku, planname: this.state.product.data.name, price: parseInt(this.state.product.data.price*100), billing: this.state.product.data.billing, date: Moment().format('DD/MM/YYYY HH:mm'), group: group, start: parseInt(date), trial: 'set', sessions: sessions, cancels: this.state.cancels, end: parseInt(cdate), sessions11: sessions11, user: this.state.family, uid: global.uid, stock: stock, count: count}).then(function(result) {});
        } else {
          // Customer and card
          var callFunction2 = Firebase.functions().httpsCallable('connectedCreateSubscriptionV2');
          callFunction2({type: 'account', account: global.userStripeConnect, clientname: global.userNname, email: email, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], client: global.spaceClient, product: this.state.product.data.product, plan: this.state.product.data.sku, planname: this.state.product.data.name, price: parseInt(this.state.product.data.price*100), billing: this.state.product.data.billing, date: Moment().format('DD/MM/YYYY HH:mm'), group: group, start: parseInt(date), trial: 'set', sessions: sessions, cancels: this.state.cancels, end: parseInt(cdate), sessions11: sessions11, user: this.state.family, uid: global.uid, stock: stock, count: count}).then(function(result) {
            //var sanitizedMessage = result.data.text;
          });
        }
      }
    } else {
      this.setState({
        showModalStock: true
      })
    }
  }


  createDebit() {
    this.hideModals()

    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      stock = this.state.product.data.stock-1
    }

    this.setState({
      loading: true,
    })
    var group = 'no'
    if(this.state.product.data.stype === 'group') {
      group = 'yes'
    } else if(this.state.product.data.stype === 'both') {
      group = 'both'
    }
    var sessions = 0
    if(this.state.product.data.sessions !== undefined) {
      sessions = this.state.product.data.sessions
    }
    var sessions11 = 0
    if(this.state.product.data.sessions11 !== undefined) {
      sessions11 = this.state.product.data.sessions11
    }

    var ds1 = Moment(this.state.date, 'X').format('DD/MM/YYYY')
    var date = Moment(ds1+' 01:30', 'DD/MM/YYYY HH:mm').format('X')

    if(Moment(date, 'X') < Moment()) {
      var start = Moment().add(1, 'day').format('DD/MM/YYYY')
      date = Moment(start+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
    }

    var cdate = this.state.cdate
    if(Moment(cdate, 'X') < Moment(date, 'X')) {
      cdate = Moment(date, 'X').add(1, 'days').format('X')
    }
    var ds2 = Moment(cdate, 'X').format('DD/MM/YYYY')
    cdate = Moment(ds2+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
    global.badgeLocked = true

    var count = 1
    if(this.state.product.data.interval !== undefined && this.state.product.data.interval !== 1) {
      count = this.state.product.data.interval
    }

    var callFunction = Firebase.functions().httpsCallable('connectedCreateSubscriptionV2');
    callFunction({type: 'create', account: global.userStripeConnect, customer: global.userStripe, client: global.spaceClient, product: this.state.product.id, plan: this.state.product.data.sku, planname: this.state.product.data.name, price: this.state.product.data.price*100, billing: this.state.product.data.billing, date: Moment().format('DD/MM/YYYY HH:mm'), group: group, start: parseInt(date), trial: 'set', sessions: sessions, cancels: this.state.cancels, end: parseInt(cdate), sessions11: sessions11, user: this.state.family, uid: global.uid, stock: stock, count: count}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });

    this.getPaymentFailsafe()
  }


  getPaymentFailsafe() {
    setTimeout(() => {
      if(this.state.client !== null) {
        if(this.state.client.id !== undefined && this.state.client.data.customer !== undefined) {
          var callFunction = Firebase.functions().httpsCallable('chargeBackup');
          callFunction({type: 'payment', account: global.userStripeConnect, customer: this.state.client.data.customer, client: this.state.client.id, uid: global.uid}).then(function(result) {});
        }
      }
    }, 100000);
  }


  updateValues(val, key) {
    this.setState({
      [key]: val
    });
  }


  selectProduct(event) {
    var tmp = null
    for(var item of this.state.products) {
      if(item.id === event.target.value) {
        tmp = item
      }
    }
    var card = true
    if(global.userCard !== '') {
      card = false
    }
    this.setState({
      card: card
    });
    this.setState({
        productId: event.target.value,
        product: tmp
    });
  }


  selectFamily(event) {
    this.setState({
      family: event.target.value
    });
  }


  getFamilyName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  setCancels() {
    var tmp = 'set'
    if(this.state.cancels === 'set') {
      tmp = 'notset'
    }
    this.setState({
      cancels: tmp
    })
  }



  // Modals ------------------------------------------------------------



  checkModalConfirm() {
    if(this.state.product.data.stock !== 0) {
      var short = false
      if(this.state.cancels === 'set') {
        var edate = Moment().add(7, 'days')
        if(this.state.product.billing === 'fortnight') {
          edate = Moment().add(14, 'days')
        } else if(this.state.product.billing === 'month') {
          edate = Moment().add(1, 'month')
        }
        if(Moment(this.state.cdate, 'X') < edate) {
          short = true
        }
      }

      if(short) {
        this.setState({
          showModalWarning: true
        })
      } else {
        this.setState({
          showModalConfirm: true
        })
      }
    } else {
      this.setState({
        showModalStock: true
      })
    }
  }

  
  hideModals() {
    this.setState({
      showModalConfirm: false,
      showModalStock: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderFamily() {
    if(this.state.product !== null) {
      if(global.userLinked !== undefined && global.userLinked.length > 0) {
        return (
          <div className="form-item">
            <label>Assigned to</label>
            <select onChange={event => this.selectFamily(event)} value={this.state.family}>
              <option value=''>{lang.t('admin:pay.allfamily')}</option>
              <option value={global.spaceClient} key={global.spaceClient}>{lang.t('common:label.yourself')}</option>
              {global.userLinked.map(item => (
                <option value={item} key={item}>{this.getFamilyName(item)}</option>
              ))}
            </select>
          </div>
        )
      }
    }
  }


  renderBottom() {
    if(this.state.productId !== '') {
      if(this.state.card) {
        return (
          <div>
            <div className="sv-20"></div>
            <FormCard values={this.state.valuesCard} error={this.state.errorCard} onChange={(event) => this.setState({valuesCard: event})}/>
            <div className="clear sv-50"></div>
            <button className="btn primary mb-10" onClick={() => this.checkCreateDebit()}>{lang.t('admin:button.membership')}</button>
            <p className="small center secondary">{lang.t('admin:pay.savecard')}</p>
          </div>
        )
      } else {
        return (
          <div>
            <div className="sv-20"></div>
            <div className="paymethod mb-30">
              <WidgetCard id={global.spaceClient}/>
              <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('admin:debit.card')}</p>
            </div>
            <button className="btn tertiary add width-12" onClick={() => this.setState({card: true})}>{lang.t('admin:button.anothercard')}</button>
            <div className="sv-60"></div>
            <button className="btn primary" onClick={() => this.checkModalConfirm()}>{lang.t('admin:button.membership')}</button>
            <div className="sv-10"></div>
          </div>
        )
      }
      
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="center" style={{fontSize: '12px', opacity: 0.6}}><strong>{lang.t('admin:debit.error1')}</strong><br/>{lang.t('admin:debit.error2')}</p>
      )
    }
  }


  renderExpiryInfo() {
    if(this.state.product !== null) {
      if(this.state.product.data.sessions !== 0) {
        return (
          <div className="infobox">
            <h5>{lang.t('admin:debit.expiry.title')}</h5>
            <p>{lang.t('admin:debit.expiry.text1')}{this.state.product.data.sessions-1}{lang.t('admin:debit.expiry.text2')}{this.state.product.data.sessions}{lang.t('admin:debit.expiry.text3')}{this.state.product.data.sessions}{lang.t('admin:debit.expiry.text4')}{this.state.product.data.sessions+1}.</p>
          </div>
        )
      }
    }
  }


  renderDebitType(item) {
    var label = '(Group)'
    if(item.data.stype === '11') {
      label = '(1:1)'
    }
    if(item.data.stype === 'both') {
      label = '(Group & 1:1)'
    }
    return label
  }


  renderProduct() {
    if(this.state.products.length > 0) {
      if(this.state.prod === '') {
        return (
          <div>
            <label>{lang.t('admin:debit.membership')}</label>
            <select onChange={event => this.selectProduct(event)} value={this.state.productId}>
              <option value={''}>- {lang.t('admin:pay.select')} -</option>
              {this.state.products.map(item => (
                <option value={item.id} key={item.id}>{item.data.name} {this.renderDebitType(item)}</option>
              ))}
            </select>
            <div className="sv-20"></div>
          </div>
          
        )
      } else {
        return (
          <div className="info">
            <label className="lft">{lang.t('admin:debit.membership')}</label>
            <p className="clear">{this.state.product.data.name}</p>
          </div>
        )
      }
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>

        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <div className="box">
              <div className="sv-20"></div>
              {this.renderProduct()}
              {this.renderFamily()}
              <InputDate label={lang.t('admin:debit.charge')} value={Moment(this.state.date, 'X').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().add(1, 'day').format('YYYY-MM-DD')} max={Moment().add(3, 'months').format('YYYY-MM-DD')} onChange={(event) => this.updateValues(Moment(event).format('X'), 'date')}/>
              <div className="sv-10"></div>
              <InfoPrice id={this.state.productId} product={this.state.product} type={'debit'}/>
              {this.renderBottom()}
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
          <div className="image empty large mb-30">
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('newdebit')}
            </div>
          </div>
          <p className="mb-30">{lang.t('admin:debit.info')}</p>
          {this.renderExpiryInfo()}
        </div>
        <div className="sv-40 clear"></div>

          <div className="clear sv-40"></div>

          <div className="header">
            <div className="content">
              <Link to={'/'+global.uid+'/admin/payments'} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{lang.t('header:title.membership')}</h2>
            </div>
          </div>

          {this.renderError()}
        </div>
        <Navigation active='admin' />
        <ModalMessageSimple type='confirmdebit' show={this.state.showModalConfirm} var={global.userName} onHide={() => this.hideModals()} clickMainButton={() => this.createDebit()}/>
        <ModalMessageSimple type='debitstock' show={this.state.showModalStock} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
        <Loader show={this.state.loading}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','admin','common','modal','message'])(withRouter(withAuthorization(condition)(MemberDebitPage)));