import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import Resizer from "react-image-file-resizer";
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Navigation from '../../components/Navigation';
import HelperClients from '../../helper/Clients';
import HelperEmptyImg from '../../helper/EmptyLarge';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberCommunityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      name: global.userName,
      community: global.community,
      images: global.communityImages,
      clients: global.clients,
      value: '',
      file: null,
      fileDisplay: null,
      margin: 0,
      showOverlay: false,
      hidden: 'hidden',
      showOverlayDelete: false,
      hiddenDelete: 'hidden',
      showOverlayComment: false,
      hiddenComment: 'hidden',
      mode: 'new',
      id: '',
      post: '',
      image: '',
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id !== 'app' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.welcomepage')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('communityLoaded', (event) => {
      var tmp = []
      for(var item of global.community) {
        if(Moment(item.data.date) < Moment()) {
          tmp.push(item)
        }
      }
      this.setState({
        community: tmp
      }, () => {
        this.getImages()
      })
    })
    EventEmitter.subscribe('communityImagesLoaded', (event) => {
      this.setState({images: global.communityImages})
    })
    this.configureData()
    this.getImages()
  }


  getImages() {
    for(var item of this.state.community) {
      if(item.data.image !== '') {
        var add = true
        for(var img of this.state.images) {
          if(img.id === item.id) {
            add = false
          }
        }
        if(add) {
          Connector.loadCommunityImage(item.id, item.data.image)
        }
      }
    }
  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.welcomepage')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var tmp = []
    for(var item of global.community) {
      if(Moment(item.data.date) < Moment()) {
        tmp.push(item)
      }
    }
    this.setState({
      name: global.userName,
      community: tmp
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getName(id) {
    var label = lang.t('common:label.member')
    if(id === global.uidUser) {
      label = lang.t('common:label.you')
    }
    for(var item of this.state.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    if(global.uid === id) {
      label = global.spaceOwner
    }
    return label
  }


  getDate(date) {
    var td= Moment().format('DD/MM/YYYY')
    var yd= Moment().add(-1, 'days').format('DD/MM/YYYY')
    var dt =Moment(date, 'X').format('DD/MM/YYYY')
    var label = Moment(date, 'X').locale(lang.language).format('D MMM YYYY LT')
    if(dt === td) {
      label = lang.t('calendar:label.today2')+' '+Moment(date, 'X').locale(lang.language).format('LT')
    }
    if(dt === yd) {
      label = lang.t('calendar:label.yesterday2')+' '+Moment(date, 'X').locale(lang.language).format('LT')
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  onChange = event => {
    this.setState({
      value: event.target.value,
    });
  };


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  addReaction(item, type, parent) {
    var tmp = ''
    var add = true
    if(type === 'like') {
      if(item.data.reaction1.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction1
        add = false
      } else {
        item.data.reaction1 += ','+global.uid
      }
      
    }
    if(type === 'party') {
      if(item.data.reaction2.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction2
        add = false
      } else {
        item.data.reaction2 += ','+global.uid
      }
    }
    if(type === 'smile') {
      if(item.data.reaction3.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction3
        add = false
      } else {
        item.data.reaction3 += ','+global.uid
      }
    }
    if(type === 'sad') {
      if(item.data.reaction4.indexOf(global.uid) !== -1) {
        tmp = item.data.reaction4
        add = false
      } else {
        item.data.reaction4 += ','+global.uid
      }
    }
    if(!add) {
      var ar = tmp.split(',')
      ar.splice(0,1)
      for(var i=0; i<ar.length; i++) {
        if(ar[i] === global.uid) {
          ar.splice(i, 1)
        }
      }
      tmp = ''
      for(var a of ar) {
        tmp += ','+a
      }
      if(type === 'like') { item.data.reaction1 = tmp }
      if(type === 'party') { item.data.reaction2 = tmp }
      if(type === 'smile') { item.data.reaction3 = tmp }
      if(type === 'sad') { item.data.reaction4 = tmp }
    }
    if(parent === '') {
      Firebase.database().ref('/community/'+global.uid+'/'+item.id).update({
        reaction1: item.data.reaction1,
        reaction2: item.data.reaction2,
        reaction3: item.data.reaction3,
        reaction4: item.data.reaction4,
      })
    } else {
      Firebase.database().ref('/community/'+global.uid+'/'+parent+'/comments/'+item.id).update({
        reaction1: item.data.reaction1,
        reaction2: item.data.reaction2,
        reaction3: item.data.reaction3,
        reaction4: item.data.reaction4,
      })
    }
    
  }



  // Create Post Overlay ------------------------------------------------------------



  showOverlay(text, id, image) {
    this.setState({showOverlay: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      if(text === '') {
        this.setState({
          hidden: '',
          margin: -height/2,
          value: '',
          file: null,
          fileDisplay: null,
          mode: 'new',
          id: '',
          image: image
        });
      } else {
        this.setState({
          hidden: '',
          margin: -height/2,
          value: text,
          file: null,
          fileDisplay: null,
          mode: 'edit',
          id: id,
          image: image
        });
      }
      
    }, 100);
  }


  hideOverlay() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      if(this.state.mode === 'new') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modal:post.title1')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>{lang.t('modal:common.close')}</button>
              <div className="clear">
                <textarea onChange={this.onChange} value={this.state.value}></textarea>
              </div>
              <div className="clear sv-20"></div>
              <label>{lang.t('modal:post.label1')}</label>
              <input type="file" placeholder={lang.t('modal:post.placeholder')} onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
              <div className="clear sv-30"></div>
              <button className="btn primary" onClick={() => this.createPost()}>{lang.t('modal:post.button')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modal:post.title2')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>{lang.t('modal:common.close')}</button>
              <div className="clear">
                <textarea onChange={this.onChange} value={this.state.value}></textarea>
              </div>
              <div className="clear sv-20"></div>
              <label>{this.state.image === '' ? lang.t('modal:post.label1') : lang.t('modal:post.label2')}</label>
              <input type="file" placeholder={lang.t('modal:post.placeholder')} onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
              <div className="clear sv-30"></div>
              <button className="btn primary" onClick={() => this.updatePost()}>{lang.t('common:button.savechanges')}</button>
            </div>
          </div>
        )
      }
      
    }
  }


  createPost() {
    if(this.state.value !== '') {
      this.hideOverlay()
      //Firebase.database().ref('/community/'+global.uid).push({
      var obj = Firebase.database().ref('/community/'+global.uid).push()
      obj.set({
        text: this.state.value,
        author: global.uidUser,
        date: parseInt(Moment().format('X')),
        image: '',
        reaction1: '',
        reaction2: '',
        reaction3: '',
        reaction4: '',
      }).then(() => {
        if(this.state.file !== null) {
          const sr = Firebase.storage().ref()
          const fr = sr.child('images/community/'+global.uid+'/'+obj.key+'.jpg')
          Resizer.imageFileResizer(
            this.state.file,
            800,
            800,
            "JPEG",
            70,
            0,
            (uri) => {
              var ar = uri.split(',')
              fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
                Firebase.database().ref('/community/'+global.uid+'/'+obj.key).update({
                  image: 'images/community/'+global.uid+'/'+obj.key+'.jpg',
                })
              }).catch((error)=>{
                EventEmitter.dispatch('showMessageError', error.message);
              })
            },
            "base64",
            200,
            200
          );
        }
        EventEmitter.dispatch('showMessage', lang.t('message:postcreated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updatePost() {
    if(this.state.value !== '') {
      this.hideOverlay()
      if(this.state.file !== null) {
        var tmp = []
        for(var img of global.communityImages) {
          if(img.id !== this.state.id) {
          tmp.push(img)}
        }
        Firebase.storage().ref('/images/community/'+global.uid+'/'+this.state.id+'.jpg').delete()
        global.communityImages = tmp
        this.setState({
          images: tmp
        }, () => {
          Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).update({
            text: this.state.value,
          }).then(() => {
            const sr = Firebase.storage().ref()
            const fr = sr.child('images/community/'+global.uid+'/'+this.state.id+'.jpg')
            Resizer.imageFileResizer(
              this.state.file,
              800,
              800,
              "JPEG",
              70,
              0,
              (uri) => {
                var ar = uri.split(',')
                fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
                  Connector.loadCommunityImage(this.state.id, this.state.image)
                }).catch((error)=>{
                  EventEmitter.dispatch('showMessageError', error.message);
                })
              },
              "base64",
              200,
              200
            );
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        })
      } else {
        Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).update({
          text: this.state.value,
        }).then(() => {
          EventEmitter.dispatch('showMessage', lang.t('message:postupdated'));
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    }
  }



  // Create Comment Overlay ------------------------------------------------------------



  showOverlayComment(text, id, post) {
    this.setState({showOverlayComment: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      if(text === '') {
        this.setState({
          hiddenComment: '',
          margin: -height/2,
          value: '',
          mode: 'new',
          id: '',
          post: post
        });
      } else {
        this.setState({
          hiddenComment: '',
          margin: -height/2,
          value: text,
          mode: 'edit',
          id: id,
          post: post
        });
      }
      
    }, 100);
  }


  hideOverlayComment() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenComment: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayComment: false});
    }, 500);
  }


  renderOverlayComment() {
    if(this.state.showOverlayComment) {
      if(this.state.mode === 'new') {
        return (
          <div className={'overlay '+this.state.hiddenComment}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modal:reply.title1')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayComment()}>{lang.t('modal:common.close')}</button>
              <div className="clear">
                <textarea onChange={this.onChange} value={this.state.value}></textarea>
              </div>
              <div className="clear sv-30"></div>
              <button className="btn primary" onClick={() => this.createComment()}>{lang.t('modal:reply.button')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hiddenComment}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modal:reply.title2')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayComment()}>{lang.t('modal:common.close')}</button>
              <div className="clear">
                <textarea onChange={this.onChange} value={this.state.value}></textarea>
              </div>
              <div className="clear sv-30"></div>
              <button className="btn primary" onClick={() => this.updateComment()}>{lang.t('common:button.savechanges')}</button>
            </div>
          </div>
        )
      }
    }
  }


  createComment() {
    if(this.state.value !== '') {
      this.hideOverlayComment()
      Firebase.database().ref('/community/'+global.uid+'/'+this.state.post+'/comments').push({
        text: this.state.value,
        author: global.uidUser,
        date: parseInt(Moment().format('X')),
        reaction1: '',
        reaction2: '',
        reaction3: '',
        reaction4: '',
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('message:reply'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updateComment() {
    if(this.state.value !== '') {
      this.hideOverlayComment()
      Firebase.database().ref('/community/'+global.uid+'/'+this.state.post+'/comments/'+this.state.id).update({
        text: this.state.value,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('message:postupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }



  // Delete Post Overlay ------------------------------------------------------------



  showOverlayDelete(id, parent, image) {
    this.setState({showOverlayDelete: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenDelete: '',
        margin: -height/2,
        file: null,
        fileDisplay: null,
        id: id,
        post: parent,
        image: image
      });
      
    }, 100);
  }


  hideOverlayDelete() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDelete: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayDelete: false});
    }, 500);
  }


  renderOverlayDelete() {
    if(this.state.showOverlayDelete) {
      if(this.state.post === '') {
        return (
          <div className={'overlay '+this.state.hiddenDelete}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modal:deletepost.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDelete()}>{lang.t('modal:common.close')}</button>
              <p className="clear mb-30">{lang.t('modal:deletepost.text')}</p>
              <button className="btn primary" onClick={() => this.deletePost()}>{lang.t('modal:deletepost.button')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hiddenDelete}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modal:deletereply.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDelete()}>{lang.t('modal:common.close')}</button>
              <p className="clear mb-30">{lang.t('modal:deletereply.text')}</p>
              <button className="btn primary" onClick={() => this.deleteReply()}>{lang.t('modal:deletereply.button')}</button>
            </div>
          </div>
        )
      }
      
    }
  }


  deletePost() {
    this.hideOverlayDelete()
    if(this.state.image !== '') {
      Firebase.storage().ref('/images/community/'+global.uid+'/'+this.state.id+'.jpg').delete()
    }
    Firebase.database().ref('/community/'+global.uid+'/'+this.state.id).remove()
    EventEmitter.dispatch('showMessage', lang.t('message:postdeleted'));
  }


  deleteReply() {
    this.hideOverlayDelete()
    Firebase.database().ref('/community/'+global.uid+'/'+this.state.post+'/comments/'+this.state.id).remove()
    EventEmitter.dispatch('showMessage', lang.t('message:replydeleted'));
  }



  // Display stuff ------------------------------------------------------------



  renderName() {
    if(this.state.name !== '' && this.state.name !== null) {
      const ar = this.state.name.split(' ')
      return ar[0]
    }
  }


  renderImage(item) {
    if(item.data.image !== '') {
      var img = ''
      for(var image of this.state.images) {
        if(image.id === item.id) {
          img = image.image
        }
      }
      return (
        <div className="image clear"style={{backgroundImage: 'url('+img+')'}}></div>
      )
    }
  }


  renderReaction(item, list, type, parent) {
    var ar = list.split(',')
    ar.splice(0, 1)
    var num = ar.length
    if(num === 0) {
      return (
        <div className={'reaction '+type} onClick={() => this.addReaction(item, type, parent)}>0</div>
      )
    } else {
      return (
        <div className={'reaction active '+type} onClick={() => this.addReaction(item, type, parent)}>{num}</div>
      )
    }
  }


  renderEdit(item) {
    if(item.data.author === global.uidUser) {
      return (
        <button className="btn tertiary small rgt edit mr-20" onClick={() => this.showOverlay(item.data.text, item.id, item.data.image)}>{lang.t('common:button.edit')}</button>
      )
    }
  }


  renderEditComment(item, id) {
    if(item.data.author === global.uidUser) {
      return (
        <button className="btn tertiary small rgt edit mr-20" onClick={() => this.showOverlayComment(item.data.text, item.id, id)}>{lang.t('common:button.edit')}</button>
      )
    }
  }


  renderDeleteComment(item, post) {
    if(item.data.author === global.uidUser) {
      return (
        <button className="btn tertiary small rgt close" onClick={() => this.showOverlayDelete(item.id, post.id, '')}>{lang.t('common:button.delete')}</button>
      )
    }
  }


  renderComments(post) {
    if(post.comments !== undefined) {
      var list = post.comments
      list.sort((a,b) => Moment(a.data.date, 'X') - Moment(b.data.date, 'X'));
      return (
        <div className="comments">
          {list.map(item => (
            <div className="comment" key={item.id}>
              {this.renderAvatarFeed(item.data.author)}
              <h4 className="lft">{this.getName(item.data.author)} - {this.getDate(item.data.date)}</h4>
              {this.renderDeleteComment(item, post)}
              {this.renderEditComment(item, post.id)}
              <p className="post clear">{item.data.text}</p>
              <div className="reactions">
                {this.renderReaction(item, item.data.reaction1, 'like', post.id)}
                {this.renderReaction(item, item.data.reaction2, 'party', post.id)}
                {this.renderReaction(item, item.data.reaction3, 'smile', post.id)}
                {this.renderReaction(item, item.data.reaction4, 'sad', post.id)}
                <div className="clear"></div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderAvatarFeed(id) {
    if(id === global.uidUser) {
      if(global.userImage === '' && global.userName !== null) {
        var inits = ''
        let arr = global.userName.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        return (
          <div className="avatar">
            <p>{inits}</p>
          </div>
        )
      } else {
        return (
          <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
        )
      }
    } else {
      for(var client of this.state.clients) {
        if(client.id === id) {
          return (
            HelperClients.getImage(client)
          )
        }
      }
      if(global.uid === id) {
        return (
          <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
        )
      }
    }
  }


  renderDeletePost(item) {
    if(item.data.author === global.uidUser) {
      return (
        <button className="btn tertiary small rgt close" onClick={() => this.showOverlayDelete(item.id, '', item.data.image)}>{lang.t('common:button.delete')}</button>
      )
    }
  }


  renderAddPost() {
    if(global.userCommunityPost) {
      return (
        <div>
          <div className="col-4">&nbsp;</div>
          <div className="col-4"><button className="btn secondary small mb-20" onClick={() => this.showOverlay('', '', '')}>{lang.t('overview:button.post')}</button></div>
        </div>
      )
    }
  }


  renderContent() {
    if(global.userCommunity && this.state.community.length > 0) {
      var list = this.state.community
      list.sort((a,b) => Moment(b.data.date, 'X') - Moment(a.data.date, 'X'));
      return (
        <div>
          {this.renderAddPost()}
          <div className="clear sv-40"></div>
          {list.map(item => (
            <div key={item.id}>
              <div className="box feed mb-20">
                {this.renderAvatarFeed(item.data.author)}
                <h4 className="lft">{this.getName(item.data.author)} - {this.getDate(item.data.date)}</h4>
                {this.renderDeletePost(item)}
                {this.renderEdit(item)}
                {this.renderImage(item)}
                <p className="post clear">{item.data.text}</p>
                <div className="reactions">
                  {this.renderReaction(item, item.data.reaction1, 'like', '')}
                  {this.renderReaction(item, item.data.reaction2, 'party', '')}
                  {this.renderReaction(item, item.data.reaction3, 'smile', '')}
                  {this.renderReaction(item, item.data.reaction4, 'sad', '')}
                  <div className="clear"></div>
                </div>
                {this.renderComments(item)}
                <button className="btn tertiary width-12 add small" onClick={() => this.showOverlayComment('', '', item.id)}>{lang.t('overview:button.reply')}</button>
              </div>
              <div className="sv-20 clear"></div>
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('community')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:posts.title')}</h3>
            <p>{lang.t('empty:posts.text')}</p>
          </div>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.welcome')} {this.renderName()}</h2>
              <Link to={'/'+global.uid+'/home'} className="tab">
                <p>{lang.t('nav:home.overview')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/community'} className="tab active">
                <p>{lang.t('nav:home.community')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear sv-40"></div>
          {this.renderContent()}
        </div>
        <Navigation active='overview' />
        {this.renderOverlay()}
        {this.renderOverlayDelete()}
        {this.renderOverlayComment()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','common','overview','calendar','modal', 'empty','message'])(withRouter(withAuthorizationMember(condition)(MemberCommunityPage)));