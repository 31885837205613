import React, { Component } from 'react';



class InfoData extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      value: props.value,
      button: props.button ?? '',
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      value: props.value,
      button: props.button ?? '',
    }
  }


  renderButton() {
    if(this.state.button !== '') {
      return <button className="btn tertiary small rgt edit" onClick={() => this.props.clickButton()}>{this.state.button}</button>
    }
  }


  render() {
    return (
      <div className="info">
        <label className={this.state.button === '' ? '' : 'lft'}>{this.state.label}</label>
        {this.renderButton()}
        <p className="clear">{this.state.value}</p>
      </div>
    )
  }
}


export default InfoData;