import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberFormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      item: null,
      sections: [],
      editing: false,
      isPre: false,
      error: false,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var pre = arr[arr.length-1]
    if(pre === 'pre-exercise') {
      id = arr[arr.length-2]
      if(id !== 'admin' && id !== 'member') {
        global.uid = id
      }
      this.setState({
        isPre: true,
        editing: true
      })
      if(global.forms.length === 0 && global.spaceClient !== '' && global.uid !== '') {
        Connector.loadForms((result) => {})
      }
    } else {
      if(id !== 'admin' && id !== 'member') {
        global.uid = id
      }
      
    }

    Connector.setUser()
    document.title = lang.t('header:title.form')+' - '+global.userBusiness
    window.scrollTo(0, 0)
    
    this.setState({
      done: true
    })
    
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('formsLoaded', (event) => this.configureData())
    this.configureData()
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.form')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var form = null
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-1] === 'pre-exercise') {
      for(var item of global.forms) {
        if(item.data.pre) {
          form = item
        }
      }
    } else {
      for(var item1 of global.forms) {
        if(item1.id === arr[arr.length-1]) {
          form = item1
        }
      }
      for(var item2 of global.clients) {
        if(item2.id === arr[arr.length-3]) {
          for(var item3 of item2.forms) {
            if(item3.id === arr[arr.length-1]) {
              form = item3
            }
          }
        }
      }
    }

    if(global.forms.length === 0 && global.spaceClient !== '' && this.state.isPre) {
      Connector.loadForms((result) => {})
    }

    var sections = []
    if(form !== null) {
      for(var sec of form.sections) {
        if(sec.data.type !== 'yesno' && sec.data.type !== 'selection' && sec.data.type !== 'rating') {
          sections.push({
            id: sec.id,
            data: {
              label: sec.data.label,
              type: sec.data.type,
              response: sec.data.response,
              mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
              seq: sec.data.seq
            }
          })
        }
        if(sec.data.type === 'yesno') {
          sections.push({
            id: sec.id,
            data: {
              label: sec.data.label,
              type: sec.data.type,
              response: sec.data.response,
              seq: sec.data.seq,
              answer1: sec.data.answer1,
              answer2: sec.data.answer2,
              detail: sec.data.detail,
              mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
            }
          })
        }
        if(sec.data.type === 'selection') {
          sections.push({
            id: sec.id,
            data: {
              label: sec.data.label,
              type: sec.data.type,
              response: sec.data.response,
              seq: sec.data.seq,
              options: sec.data.options,
              multiple: sec.data.multiple,
              mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
            }
          })
        }
        if(sec.data.type === 'rating') {
          sections.push({
            id: sec.id,
            data: {
              label: sec.data.label,
              type: sec.data.type,
              response: sec.data.response,
              seq: sec.data.seq,
              num: sec.data.num,
              mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
            }
          })
        }
      }
    }

    if(!this.state.editing) {
      this.setState({
        item: form,
        sections: sections,
      })
    }
    if(this.state.isPre) {
      this.setState({
        item: form,
        sections: sections,
        editing: true
      })
    }
  }



  // Interactive stuff ------------------------------------------------------------



  onChangeField(value, item) {
    var tmp = this.state.sections
    for(var sec of tmp) {
      if(sec.id === item.id) {
        sec.data.response = value
      }
    }
    this.setState({
      sections: tmp
    })
  }


  onChangeDetails(value, item) {
    var tmp = this.state.sections
    for(var sec of tmp) {
      if(sec.id === item.id) {
        sec.data.detail = value
      }
    }
    this.setState({
      sections: tmp
    })
  }


  onChangeSelection(value, item) {
    var tmp = this.state.sections
    var arr = item.data.response.split(',')
    var exists = false
    if(arr.length > 0) {
      for(var i=arr.length-1; i>-1; i--) {
        if(arr[i] === String(value)) {
          arr.splice(i, 1)
          exists = true
        }
        if(arr[i] === '') {
          arr.splice(i, 1)
        }
      }
    }
    if(!exists) {
      if(item.data.multiple) {
        arr.push(String(value))
      }
      else {
        arr = [String(value)]
      }
    }
    var final = ''
    for(var a of arr) {
      final += ','+a
    }
    final = final.substring(1)
    for(var sec of tmp) {
      if(sec.id === item.id) {
        sec.data.response = final
      }
    }
    this.setState({
      sections: tmp
    })
  }


  getSelectionClass(value, item, type) {
    var label = 'inactive'
    if(type === 'static') {
      label = 'none'
    }
    var arr = item.data.response.split(',')
    if(arr.indexOf(String(value)) !== -1) {
      label = 'active'
      if(type === 'static') {
        label = ''
      }
    }
    return label
  }


  updateResponse() {
    var passed = true
    for(var sc of this.state.sections) {
      if(sc.data.mandatory && sc.data.response === '') {
        passed = false
      }
    }
    if(passed) {
      global.activityLocked = false
      this.setState({
        editing: false,
        error: false,
      }, () => {
        var assembled = {
          name: this.state.item.data.name,
          version: this.state.item.data.version,
          pre: this.state.item.data.pre,
          uid: this.state.item.data.uid,
          date: parseInt(Moment().format('X')),
          sections: [],
        }

        for(var item of this.state.sections) {
          if(item.data.type !== 'yesno' && item.data.type !== 'selection' && item.data.type !== 'rating') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              seq: item.data.seq,
              mandatory: (item.data.mandatory === undefined ? false : item.data.mandatory),
            }
          } else if(item.data.type === 'yesno') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              answer1: item.data.answer1,
              answer2: item.data.answer2,
              detail: item.data.detail,
              seq: item.data.seq,
              mandatory: (item.data.mandatory === undefined ? false : item.data.mandatory),
            }
          } else if(item.data.type === 'selection') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              options: item.data.options,
              multiple: item.data.multiple,
              seq: item.data.seq,
              mandatory: (item.data.mandatory === undefined ? false : item.data.mandatory),
            }
          } else if(item.data.type === 'rating') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              num: item.data.num,
              seq: item.data.seq,
              mandatory: (item.data.mandatory === undefined ? false : item.data.mandatory),
            }
          }
        }

        Firebase.database().ref('clients/'+global.uid+'/'+global.spaceClient+'/forms/'+this.state.item.id).set(
          assembled
        ).then((data)=>{
          EventEmitter.dispatch('showMessage', lang.t('message:response'));
          if(this.state.isPre) {
            if(Firebase.auth().currentUser.uid === 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
              this.props.history.push('/'+global.uid+'/thankyou')
            } else {
              this.props.history.push('/'+global.uid+'/home')
            }
            
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        Firebase.database().ref('activity/'+global.uid).push({
          type: "form",
          data: global.spaceClient+','+this.state.item.id,
          date: Moment().format('DD/MM/YYYY HH:mm')
        })
      })
    } else {
      this.setState({
        error: true
      })
    }
  }


  cancelEditing() {
    this.setState({
      editing: false
    }, () => {
      this.configureData()
    })
  }



  // Display stuff ------------------------------------------------------------



  renderText(item) {
    if(this.state.editing) {
      return (
        <input type="text" value={item.data.response} onChange={(event) => this.onChangeField(event.target.value, item)} />
      )
    } else {
      return (
        <p style={{whiteSpace: 'pre-wrap'}}>{item.data.response === '' ? '-' : item.data.response}</p>
      )
    }
  }


  getResponse(value) {
    var label = lang.t('admin:form.yes')
    if(value === '1') {
      label = lang.t('admin:form.no')
    }
    return label
  }


  renderYesNo(item) {
    if(this.state.editing) {
      return (
        <div className="radios">
          <button className={item.data.response === '0' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.onChangeField('0', item)}>{lang.t('admin:form.yes')}</button>
          <button className={item.data.response === '1' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.onChangeField('1', item)}>{lang.t('admin:form.no')}</button>
        </div>
      )
    } else {
      return (
        <p>{item.data.response === '' ? '-' : this.getResponse(item.data.response)}</p>
      )
    }
  }


  renderYesNoDetail(item) {
    if(this.state.editing) {
      if(item.data.answer1 && !item.data.answer2 && item.data.response === '0') {
        return (
          <div>
            <div className="space-10"></div>
            <label>{lang.t('admin:form.providedetails')}</label>
            <input type="text" value={item.data.detail} onChange={(event) => this.onChangeDetails(event.target.value, item)} />
          </div>
        )
      } else if(!item.data.answer1 && item.data.answer2 && item.data.response === '1') {
        return (
          <div>
            <div className="space-10"></div>
            <label>{lang.t('admin:form.providedetails')}</label>
            <input type="text" value={item.data.detail} onChange={(event) => this.onChangeDetails(event.target.value, item)} />
          </div>
        )
      } else if(item.data.answer1 && item.data.answer2 && item.data.response !== '') {
        return (
          <div>
            <div className="space-10"></div>
            <label>{lang.t('admin:form.providedetails')}</label>
            <input type="text" value={item.data.detail} onChange={(event) => this.onChangeDetails(event.target.value, item)} />
          </div>
        )
      }
    } else {
      if(item.data.detail !== '') {
        return (
          <p>{item.data.detail}</p>
        )
      }
    }
  }


  renderRate(item) {
    if(this.state.editing) {
      var per = (100/item.data.num)+'%'
      var list = []
      for(var i=0; i<item.data.num; i++) {
        list.push(i+1)
      }
      return (
        <div className="radios">
          {list.map((num) => (
            <button className={item.data.response === String(num) ? 'radio lft active' : 'radio lft'} style={{width: per}} onClick={() => this.onChangeField(String(num), item)}>{num}</button>
          ))}
        </div>
      )
    } else {
      return (
        <p>{item.data.response === '' ? '-' : item.data.response+' of '+item.data.num}</p>
      )
    }
  }


  renderSelection(item) {
    if(this.state.editing) {
      return (
        <ul className="selection">
          {item.data.options.map((option, index) => (
            <li className={this.getSelectionClass(index, item, 'edit')} onClick={() => this.onChangeSelection(index, item)}>
              {option}
            </li>
          ))}
        </ul>
      )
    } else {
      if(item.data.response !== '') {
        return (
          <ul className="selection">
          {item.data.options.map((option, index) => (
            <li className={'default '+this.getSelectionClass(index, item, 'static')}>
              {option}
            </li>
          ))}
        </ul>
        )
      } else {
        return (
          <div>
            <div className="clear space-10"></div>
            <p>-</p>
          </div>
        )
      }
    }
  }


  renderQuestion(item) {
    if(item.data.type === 'header') {
      return (
        <div>
          <h4 className="mb-30">{item.data.label}</h4>
          <div className="clear"></div>
        </div>
      )
    } else if(item.data.type === 'paragraph') {
      return (
        <div>
          <p>{item.data.label}</p>
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'text') {
      return (
        <div>
          <label>{item.data.label}</label>
          {this.renderText(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'yesno') {
      return (
        <div>
          <label>{item.data.label}</label>
          {this.renderYesNo(item)}
          {this.renderYesNoDetail(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'selection') {
      return (
        <div>
          <label>{item.data.label}</label>
          <span className={item.data.multiple ? 'pill' : 'none'}>{lang.t('admin:form.multiple')}</span>
          {this.renderSelection(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'rating') {
      return (
        <div>
          <label>{item.data.label}</label>
          {this.renderRate(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    }
  }


  renderButtonLater() {
    if(Firebase.auth().currentUser.uid !== 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
      return (
        <Link to={'/'+global.uid+'/home'} className="btn tertiary width-12">{lang.t('admin:button.later')}</Link>
      )
    }
  }


  renderButton() {
    if(this.state.isPre) {
      return (
        <div>
          <button className="btn primary mt-20" onClick={() => this.updateResponse()}>{lang.t('admin:savecontinue')}</button>
          <div className="clear sv-20"></div>
          {this.renderButtonLater()}
        </div>
      )
    } else {
      if(this.state.editing) {
        return (
          <div>
            <button className="btn primary mt-20" onClick={() => this.updateResponse()}>{lang.t('admin:button.save')}</button>
            <div className="clear sv-20"></div>
            <button className="btn tertiary width-12 close" onClick={() => this.cancelEditing()}>{lang.t('common:button.cancel')}</button>
          </div>
        )
      } else {
        if(this.state.item.data.date === undefined) {
          return (
            <div>
              <button className="btn secondary mt-20" onClick={() => this.setState({editing: true})}>{lang.t('admin:button.fillout')}</button>
              <div className="clear"></div>
            </div>
          )
        } else {
          if(this.state.item.data.lock === undefined || !this.state.item.data.lock) {
            return (
              <div>
                <button className="btn secondary mt-20 mb-20" onClick={() => this.setState({editing: true})}>{lang.t('admin:button.editresponse')}</button>
                <div className="clear"></div>
              </div>
            )
          }
        }
      }
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <div className="inline-message red mb-20">
          <strong>{lang.t('admin:error.filldocument')}</strong>
        </div>
      )
    }
  }


  renderSidebar() {
    if(!this.state.isPre) {
      return (
        <div className="col-4 sidebar">
          <h2 className="mb-40">{this.state.item.data.name}</h2>
          <div className="infobox">
            <p>{this.state.item.data.date === undefined ? lang.t('admin:label.notcompleted') : lang.t('admin:label.completed')+' '+Moment(this.state.item.data.date, 'X').locale(lang.language).format('D MMM YYYY')}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="col-4 sidebar">
          <h2 className="mb-40">{lang.t('admin:form.pre.title')}</h2>
          <div className="infobox green">
            <p>{lang.t('admin:form.pre.text')}</p>
          </div>
        </div>
      )
    }
  }


  renderNav() {
    if(!this.state.isPre) {
      return <Navigation active='admin' />
    }
  }


  renderHeader() {
    if(!this.state.isPre) {
      return (
        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/admin/forms'} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{lang.t('header:title.form')}</h2>
          </div>
        </div>
      )
    }
  }


  renderBase() {
    if(this.state.item === null) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content mt-20"></div>
          {this.renderHeader()}
          {this.renderNav()}
        </div>
      )
    } else {
      var list = this.state.sections
      list.sort((a, b) => a.data.seq - b.data.seq)
      return (
        <div className={'theme-'+global.spaceTheme}>
          
          <div className="content mt-20">
            <div className="col-8">
              <div className="box question">
                {list.map((item) => (
                  <div>
                    {this.renderQuestion(item)}
                  </div>
                ))}
                <div className="clear sv-20"></div>
                {this.renderError()}
                {this.renderButton()}
                <div className="clear sv-20"></div>
              </div>
            </div>
            {this.renderSidebar()}
            <div className="sv-40 clear"></div>
            
          </div>
          {this.renderHeader()}
          {this.renderNav()}
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withTranslation(['header','admin','common','message'])(withRouter(withAuthorizationMember(condition)(MemberFormPage)));

