import React, { Component } from 'react';



class InputRadio extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label ?? '',
      value: props.value,
      values: props.values,
      info: props.info ?? '',
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label ?? '',
      value: props.value,
      values: props.values,
      info: props.info ?? '',
    }
  }


  calculateWidth() {
    var calc = 100/this.state.values.length
    return calc+'%'
  }


  renderInfo() {
    if(this.state.info !== '') {
      return (
        <p style={{fontSize: 11,marginTop: 5, marginBottom: 0}}>{this.state.info}</p>
      )
    }
  }


  render() {
    return (
      <div className="form-item">
        <label className={this.state.label === '' ? 'none' : ''}>{this.state.label}</label>
        <div className="radios">
          {this.state.values.map(item => (
            <button key={item.value} className={this.state.value === item.value ? 'radio active' : 'radio'} style={{width: this.calculateWidth(), marginTop: 0}} onClick={() => this.props.clickElement(item.value)}>{item.name}</button>
          ))}
        </div>
        {this.renderInfo()}
      </div>
    )
  }
}


export default InputRadio;