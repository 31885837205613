import React, { Component } from 'react';
import Moment from 'moment';



class InputDate extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      value: props.value,
      format: props.format,
      placeholder: props.placeholder ?? '',
      info: props.info ?? '',
      error: props.error ?? false,
      errormsg: props.errormsg ?? '',
      min: props.min ?? null,
      max: props.max ?? null,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      value: props.value,
      format: props.format,
      placeholder: props.placeholder ?? '',
      info: props.info ?? '',
      error: props.error ?? false,
      errormsg: props.errormsg ?? '',
      min: props.min ?? null,
      max: props.max ?? null,
    }
  }


  onChange = event => {
    var valid = false
    valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    if(valid) {
      this.props.onChange(Moment(event.target.value, 'YYYY-MM-DD').format(this.state.format))
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error">{this.state.errormsg}</p>
      )
    }
  }


  renderInfo() {
    if(this.state.info !== '') {
      return (
        <p className="info">{this.state.info}</p>
      )
    }
  }


  render() {
    return (
      <div className="form-item">
        <label>{this.state.label}</label>
        <input type="date" className={this.state.error ? 'error': ''} value={this.state.value} placeholder={this.state.placeholder} min={this.state.min} max={this.state.max} onChange={this.onChange}/>
        {this.renderError()}
        {this.renderInfo()}
      </div>
    )
  }
}


export default InputDate;