import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import Firebase from 'firebase';
import EventEmitter from '../../helper/Emitter';
import Message from '../../components/Message';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class PasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      type: 'login',
      reset: '',
      password: '',
      sent: false,
      link1: ROUTES.LOGIN,
      link2: ROUTES.SIGNUP
    };
  }


  componentDidMount() {
    this.setState({
      link1: ROUTES.LOGIN,
      LINK2: ROUTES.SIGNUP
    })
  }


  onChangeReset = event => {
    this.setState({reset: event.target.value});
  };


  resetPassword() {
    Firebase.auth().sendPasswordResetEmail(this.state.reset).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('auth:pass.emailsent'));
      this.setState({
        sent: true
      })
    }).catch(function(error) {
      EventEmitter.dispatch('showMessageError', error.message);
    });
  }


  reset2() {
    this.setState({
      sent: true
    })
    var callFunction = Firebase.functions().httpsCallable('sendResetEmail');
    callFunction({email: this.state.reset, name: 'User' }).then(function(result) {});
  }



  // Display stuff ------------------------------------------------------------



  renderForm() {
    return (
      <div className="login content">
        <div className="box login static">
          <div style={{textAlign: 'center'}}>
            <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
          </div>
          <h1 className="mt-20 mb-30">{!this.state.sent ? lang.t('auth:pass.title1') : lang.t('auth:pass.title2')}</h1>
          <p className="clear mb-30" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:pass.label1')}</p>
          <label>{lang.t('auth:form.email.label')}</label>
          <input type="text" placeholder={lang.t('auth:form.email.placeholder')} value={this.state.reset} onChange={this.onChangeReset} name="email" />
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.resetPassword()}>{lang.t('auth:pass.sendreset')}</button>
          <div className="sv-30"></div>
          <Link to={this.state.link1} className="btn tertiary width-12 link">{lang.t('common:button.cancel')}</Link>
        </div>
        <Message/>
      </div>
    )
  }


  renderConfirm() {
    return (
      <div className="login">
        <div className="content">
          <div className="box signin">
            <div className="center">
              <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
            </div>
            <h1 className="mb-30">{lang.t('auth:pass.title2')}</h1>
            <p className="clear">{lang.t('auth:pass.label2')}</p>
            <p>{lang.t('auth:pass.label3')}</p>
            <div className="space-20"></div>
            <Link to={this.state.link1} className="btn primary">{lang.t('auth:pass.login')}</Link>
          </div>
        </div>
        <Message/>
      </div>
    )
  }


  renderContent() {
    if(!this.state.sent) {
      return (
        <p className="clear mb-30" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:pass.label4')}</p>
      )
    } else {
      return (
        <p className="clear mb-30">{lang.t('auth:pass.label5')}</p>
      )
    }
  }


  renderBottom() {
    if(!this.state.sent) {
      return (
        <div>
          <label>{lang.t('auth:form.email.label')}</label>
          <input type="text" placeholder={lang.t('auth:form.email.placeholder')} value={this.state.reset} onChange={this.onChangeReset} name="email" />
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.resetPassword()}>{lang.t('auth:pass.sendreset')}</button>
          <div className="sv-20"></div>
          <Link to={this.state.link1} className="btn width-12 tertiary">{lang.t('common:button.cancel')}</Link>
          <div className="sv-30"></div>
          <div className="signup">
            <Link to={this.state.link2} className='btn tertiary width-12'>{lang.t('auth:pass.noaccount')}</Link>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="sv-20"></div>
          <Link to={this.state.link1} className="btn primary">{lang.t('auth:pass.login')}</Link>
        </div>
      )
    }
  }


  render() {
    if(Firebase.auth().currentUser != null) {
      if(Firebase.auth().currentUser.displayName !== null) {
        this.props.history.push(ROUTES.LOADING);
      } else {
        this.props.history.push(ROUTES.SIGNUP);
      }
      return (
        <div/>
      )
    } else {
      return (
        <div className="content login static">
          <div className="box login static">
            <div style={{textAlign: 'center'}}>
              <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
            </div>
            <h1 className="mt-20 mb-30">{!this.state.sent ? lang.t('auth:pass.title1') : lang.t('auth:pass.title2')}</h1>
            {this.renderContent()}
            {this.renderBottom()}
          </div>
        <Message/>
        </div>
      )
    }
  }
}


class PasswordFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
    };
  }
}


const PasswordFormHome = compose(
  withRouter,
  withFirebase,
)(PasswordFormBase);


export default withTranslation(['auth','common'])(PasswordPage);
export { PasswordFormHome };